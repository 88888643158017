import { FC, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { routing } from 'src/utils';
import { Seo } from 'src/constants';
import { ActionButton } from 'src/components/Forms';
import { useBottomPopup, ChangeConfirmedPopup } from 'src/components';
import RightSideFields from './blogFields/RightSideFields';
import LeftSideFields from './blogFields/LeftSideFields';
import * as service from 'src/service';
import { blogValidationSchema } from '../utils/validation';
import { blogInitialValues } from '../utils/values';
import { Form, Formik, FormikValues } from 'formik';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useStyles } from './BlogStyles';

type Props = {
  edit?: boolean;
};

const Blog: FC<Props> = ({ edit }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { slug } = useParams<{ slug: string }>();
  const classes = useStyles();
  const navigate = useNavigate();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [, setIsLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormikValues>(() => blogInitialValues);
  const [preview, setPreview] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (edit && slug) {
      service.blog.getBlogBySlug(slug).then((res) => {
        setForm((prevState) => ({
          ...prevState,
          ...res.data.blog,
        }));
        setTags(res.data.blog.tags);
        setPreview(res.data.blog.thumbnail.url);
      });
    }
  }, [edit, slug]);

  const onCreateBlog = (values: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('content', values.content);
    formData.append('category', values.category);
    formData.append('tags', tags.join(','));
    if (values.thumbnail && values.thumbnail.length > 0) {
      formData.append('thumbnail', values.thumbnail);
    }
    const data = Object.fromEntries(formData.entries());

    if (slug) {
      service.blog
        .updateBlog(slug, data)
        .then((res) => {
          if (res.response.status === 200) {
            changeContinuePopup('Blog updated successfully');
            setTimeout(() => {
              changeContinuePopup('Blog updated successfully');
              navigate(routing.blog);
            }, 3000);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      service.blog
        .createBlog(data)
        .then((res) => {
          if (res.response.status === 201) {
            changeContinuePopup('Blog created successfully');
            setTimeout(() => {
              changeContinuePopup('Blog created successfully');
              navigate(routing.blog);
            }, 3000);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box>
      <Seo title={edit ? 'Edit Blog' : 'Create Blog'} />
      <Box className={classes.titleWrapper}>
        {edit ? <h1>Edit Blog</h1> : <h1>Create Blog</h1>}
        {changingConfirmed && (
          <ChangeConfirmedPopup
            successText={successText}
            changingConfirmed={changingConfirmed}
          />
        )}
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={form}
        validationSchema={blogValidationSchema}
        onSubmit={async (values, actions) => {
          await onCreateBlog(values);
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          values,
        }) => (
          <Form onSubmit={handleSubmit} encType='multipart/form-data'>
            <Grid container spacing={2}>
              <LeftSideFields
                edit={edit}
                handleChange={handleChange}
                values={values}
                isSubmitting={isSubmitting}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                tags={tags}
                setTags={setTags}
                setFieldValue={setFieldValue}
                isMobile={isMobile}
              />
              <RightSideFields
                preview={preview}
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setPreview={setPreview}
                handleChange={handleChange}
              />
            </Grid>
            {isMobile && (
              <ActionButton
                className={classes.btnSubmit}
                type='submit'
                disabled={isSubmitting}
              >
                {edit ? 'Save' : 'Submit'}
              </ActionButton>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Blog;
