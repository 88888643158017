import React, { FC, useEffect, useState } from 'react';
import {
  InputField,
  ActionButton,
  TextAreaField,
  SelectField,
  InputLabel,
} from 'src/components/Forms';
import * as service from 'src/service';
import { FormikValues } from 'formik';
import { TagsSuggestions } from 'src/components';
import { Box, Grid } from '@mui/material';
import { useStyles } from '../BlogStyles';

interface ILeftSideFields {
  edit?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: FormikValues;
  isSubmitting: boolean;
  errors: { [key: string]: any };
  touched: { [key: string]: any };
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  tags: string[];
  setTags: (tags: string[]) => void;
  setFieldValue: (field: string, value: any) => void;
  isMobile: boolean;
}

interface IBlogCategory {
  _id: string;
  name: string;
}
const LeftSideFields: FC<ILeftSideFields> = ({
  edit,
  handleChange,
  values,
  isSubmitting,
  errors,
  touched,
  handleBlur,
  tags,
  setTags,
  setFieldValue,
  isMobile,
}) => {
  const classes = useStyles();
  const [categories, setCategories] = useState<IBlogCategory[]>([]);
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [text, setText] = useState<string>('');

  useEffect(() => {
    service.categories_blog
      .getCategories()
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (!!text.length) {
      service.blog
        .getSuggetedTags(text.toLowerCase())
        .then((res) => {
          setSuggestedTags(
            res.data.tags.filter(
              (suggest: { _id: string }) =>
                !tags.includes(suggest._id.toLowerCase())
            )
          );
        })
        .catch(console.error);
    }
  }, [text, tags]);

  return (
    <Grid item xs={12} md={6}>
      <Box className={classes.inputWrapper}>
        <InputLabel id='title' title=' Title' />
        <InputField
          name='title'
          placeholder='enter title...'
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>
      <Box className={classes.inputWrapper}>
        <InputLabel id='category' title=' Category' />
        <SelectField
          value={values.category}
          name='category'
          options={categories.map((category) => ({
            _id: category.name,
            value: category.name,
          }))}
          sx={{ minWidth: 100, Height: 40 }}
          onChange={(e) => setFieldValue('category', e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          placeholder='choose category...'
          onBlur={handleBlur}
        />
        {errors.category && touched.category && (
          <Box sx={{ color: 'red', fontSize: '10px' }}>{errors.category}</Box>
        )}
      </Box>

      <TagsSuggestions
        tags={tags}
        suggestedTags={suggestedTags}
        setTags={setTags}
        text={text}
        setText={setText}
        handleChange={handleChange}
        handleBlur={handleBlur}
        inputName='tags'
      />

      <Box className={classes.inputWrapper}>
        <InputLabel id='description' title=' Description' />
        <TextAreaField
          name='description'
          value={values.description}
          minRows={8}
          placeholder='enter description...'
          className={
            errors.description && touched.description
              ? classes.error
              : classes.textArea
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.description && touched.description && (
          <Box sx={{ color: 'red', fontSize: '10px' }}>
            {errors.description}
          </Box>
        )}
      </Box>
      {!isMobile && (
        <ActionButton
          className={classes.btnSubmit}
          type='submit'
          disabled={isSubmitting}
        >
          {edit ? 'Save' : 'Submit'}
        </ActionButton>
      )}
    </Grid>
  );
};

export default LeftSideFields;
