import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  registerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4rem',
  },

  inputContainer: {
    padding: '0 1rem',
  },

  logoWrapper: {
    backgroundColor: theme.palette.secondary[300],
    padding: '0.7rem',
    marginBottom: '2rem',
  },

  logo: {
    width: '100px',
  },

  activeTab: {
    borderBottom: `2px solid ${theme.palette.secondary[500]}`,
    color: theme.palette.secondary[500],
  },

  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    marginBottom: '1rem',
  },
  forgot: {
    textAlign: 'center',
  },

  btnSubmit: {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    margin: '2rem auto 0 ',
    display: 'block',

    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  link: {
    textDecoration: 'none',
    color: theme.palette.primary[100],
  },
}));
