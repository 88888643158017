import { FC, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Seo } from 'src/constants';
import { ActionButton } from 'src/components/Forms';
import { routing } from 'src/utils';
import cx from 'classnames';
import LeftSideFields from './PropertyFields/LeftSideFields';
import RightSideFields from './PropertyFields/RightSideFields';
import * as service from 'src/service';
import { useBottomPopup, ChangeConfirmedPopup } from 'src/components';
import { PropertyValidationSchema } from '../utils/validation';
import { propertyInitialValues } from '../utils/values';
import { Form, Formik, FormikValues } from 'formik';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useStyles } from './PropertiesStyles';

type Props = {
  edit?: boolean;
};

const Properties: FC<Props> = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { edit } = props;
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [propertyImage, setPropertyImage] = useState<string[]>([]);
  const [form, setForm] = useState<FormikValues>(() => propertyInitialValues);
  const [, setIsLoading] = useState<boolean>(false);
  const [imagesPreview, setImagesPreview] = useState<string[]>([]);

  useEffect(() => {
    if (edit && slug) {
      service.property.getPropertyBySlug(slug).then((res) => {
        setForm((prevState) => ({
          ...prevState,
          ...res.data.property,
        }));
        setImagesPreview((prev) => [
          ...prev,
          ...res.data.property.propertyImage.map(
            (image: { url: string }) => image.url
          ),
        ]);
      });
    }
  }, [edit, slug]);

  const onRemoveImage = (index: number) => {
    setImagesPreview((prev) => prev.filter((_, i) => i !== index));
    setPropertyImage((prev) => prev.filter((_, i) => i !== index));
  };

  const onCreateProperty = (values: any) => {
    setIsLoading(true);
    const data = {
      propertyStatus: values.propertyStatus,
      propertyType: values.propertyType,
      propertyName: values.propertyName,
      propertyDescription: values.propertyDescription,
      propertyPrice: values.propertyPrice,
      propertySize: values.propertySize,
      propertyFeatured: values.propertyFeatured,
      category: values.category,
      propertyAddress: {
        street: values.propertyAddress.street,
        city: values.propertyAddress.city,
        country: values.propertyAddress.country,
        zipCode: values.propertyAddress.zipCode,
      },
      propertyAdditionalDetails: {
        title: values.propertyAdditionalDetails.title,
        benefits: values.propertyAdditionalDetails.benefits,
      },
      propertyImage: propertyImage?.length ? propertyImage : undefined,
    };

    if (slug) {
      service.property
        .updateProperty(slug, data)
        .then((res) => {
          if (res.response.status === 200) {
            changeContinuePopup('Property updated successfully');
            setTimeout(() => {
              changeContinuePopup('Property updated successfully');
              navigate(routing.property);
            }, 3000);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      service.property
        .createProperty(data)
        .then((res) => {
          if (res.response.status === 201) {
            changeContinuePopup('Property created successfully');
            setTimeout(() => {
              changeContinuePopup('Property created successfully');
              navigate(routing.property);
            }, 3000);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box>
      <Seo title={edit ? 'Edit Property' : 'Create Property'} />
      <Box className={classes.titleWrapper}>
        {edit ? <h1> Edit Property</h1> : <h1> Create Property</h1>}
        {changingConfirmed && (
          <ChangeConfirmedPopup
            successText={successText}
            changingConfirmed={changingConfirmed}
          />
        )}
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={form}
        validationSchema={PropertyValidationSchema}
        onSubmit={async (values, actions) => {
          await onCreateProperty(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          values,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Form onSubmit={handleSubmit} encType='multipart/form-data'>
            <Grid container spacing={2}>
              <LeftSideFields
                edit={edit}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                isMobile={isMobile}
                isSubmitting={isSubmitting}
                isValid={isValid}
                dirty={dirty}
                touched={touched}
                errors={errors}
              />
              <RightSideFields
                onRemoveImage={onRemoveImage}
                imagesPreview={imagesPreview}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                setImagesPreview={setImagesPreview}
                setPropertyImage={setPropertyImage}
                propertyImage={propertyImage}
                touched={touched}
                errors={errors}
              />
            </Grid>
            {isMobile && (
              <ActionButton
                type='submit'
                className={cx(classes.btnSubmit, {
                  [classes.btnSubmitMobile]: isMobile,
                })}
                disabled={isSubmitting}
              >
                {edit ? 'Save' : 'Submit'}
              </ActionButton>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Properties;
