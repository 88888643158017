import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../../router/Navigation';
import cx from 'classnames';
import {
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ChevronRightOutlined } from '@mui/icons-material';
import { useStyles } from './SideBarStyles';

interface IListMenu {
  activeLink: string;
  setActiveLink: (value: string) => void;
}

const ListMenu: FC<IListMenu> = ({ activeLink, setActiveLink }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <List>
      {Navigation.adminNavigation.map((route, index) => {
        if (!route.icon) {
          return (
            <Typography key={index} className={classes.labelName}>
              {route.label}
            </Typography>
          );
        }
        if (route.label) {
          return (
            <ListItem
              key={index}
              disablePadding
              className={cx({
                [classes.activeLabel]:
                  activeLink === route.pathnames ||
                  activeLink.includes(route.pathnames),
                [classes.inactiveLabel]:
                  activeLink !== route.pathnames &&
                  !activeLink.includes(route.pathnames),
              })}
              onClick={() => {
                navigate(`${route.path}`);
                setActiveLink(route.pathnames);
              }}
            >
              <ListItemButton>
                <ListItemIcon
                  className={cx({
                    [classes.activeIcon]:
                      activeLink === route.pathnames ||
                      activeLink.includes(route.pathnames),
                    [classes.inactiveIcon]:
                      activeLink !== route.pathnames &&
                      !activeLink.includes(route.pathnames),
                  })}
                >
                  {route.icon}
                </ListItemIcon>
                <ListItemText primary={route.label} />
                {activeLink === route.pathnames ||
                  (activeLink.includes(route.pathnames) && (
                    <ChevronRightOutlined sx={{ ml: 'auto' }} />
                  ))}
              </ListItemButton>
            </ListItem>
          );
        }
        return null;
      })}
    </List>
  );
};

export default ListMenu;
