import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputField, ActionButton, InputLabel } from 'src/components/Forms';
import { login } from 'src/utils/routing';
import { Form, Formik, FormikValues } from 'formik';
import * as service from 'src/service';
import { EmailValidationSchema } from '../../utils/validation';
import { emailInitialValues } from '../../utils/values';
import { Box } from '@mui/material';
import { useStyles } from '../ResetPasswordStyles';

interface IEnterEmail {
  changeContinuePopup: (message: string) => void;
}

const EnterEmail: FC<IEnterEmail> = ({ changeContinuePopup }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const onEnterEmail = (values: FormikValues, actions: any) => {
    service.auth
      .forgotPassword(values)
      .then((res) => {
        if (!res.response.ok) throw res;
        return res;
      })
      .then((res) => {
        console.log(res);
        if (res.response.status === 200) {
          actions.resetForm({
            values: emailInitialValues,
          });
          changeContinuePopup('Recovery link sent to your email');
          setTimeout(() => {
            changeContinuePopup('Recovery link sent to your email');
            navigate(login);
          }, 3000);
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        actions.setFieldError(
          'email',
          err.response.status === 404
            ? 'User not found with this email'
            : 'Something went wrong'
        );
      });
  };

  return (
    <Box className={classes.inputContainer}>
      <Formik
        initialValues={emailInitialValues}
        validationSchema={EmailValidationSchema}
        onSubmit={onEnterEmail}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleChange,
          handleBlur,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.inputWrapper}>
              <InputLabel id='email' title='Email' />
              <InputField
                type='email'
                name='email'
                placeholder='enter email...'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>

            <ActionButton
              type='submit'
              disabled={!isValid || isSubmitting || !dirty}
              className={classes.btnSubmit}
            >
              submit
            </ActionButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EnterEmail;
