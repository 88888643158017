import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Seo } from 'src/constants';
import { routing } from 'src/utils';
import Table from 'src/components/Table';
import DeletePopUp from './PopUp/DeletePopUp';
import moment from 'moment';
import * as service from 'src/service';
import {
  TriggerButtons,
  useBottomPopup,
  ChangeConfirmedPopup,
} from 'src/components';
import { Box } from '@mui/material';
import { IModalDisplay } from './utils/types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useStyles } from './AllPropertiesStyles';

const AllProperties: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [properties, setProperties] = useState<Record<string, any>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();

  const [openDeleteModal, setOpenDeleteModal] = useState<IModalDisplay>({
    show: false,
    data: '',
  });

  useEffect(() => {
    setIsLoading(true);
    service.property
      .getProperties()
      .then((res) => {
        setProperties(res.data.properties);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }, [changingConfirmed]);

  const onOpenDeleteModal = (data: string) => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data,
    }));
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data: '',
    }));
  };

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 100, sortable: false },
    { field: 'propertyName', headerName: ' Name', width: 100 },
    { field: 'propertyPrice', headerName: 'Price', width: 100 },
    { field: 'propertyStatus', headerName: ' Status', width: 100 },
    {
      field: 'createdAt',
      headerName: 'CreatedAt',
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <span>{moment(params.value).format('DD-MM-YYYY')}</span>
      ),
    },
    { field: 'propertyType', headerName: ' Type', width: 100 },
    {
      field: 'propertyAddress',
      headerName: 'State',
      width: 100,
      valueFormatter: (params) => params.value.city,
    },

    { field: 'propertySize', headerName: 'Size', width: 100 },

    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <TriggerButtons
          onEdit={() => navigate(`${routing.editProperty}/${params.row.slug}`)}
          onDelete={() => onOpenDeleteModal(params.row._id)}
        />
      ),
    },
  ];

  const onDeleteProperties = (id: string) => {
    service.property
      .deleteProperty(id)
      .then((res) => {
        const newProperties = properties.filter((item) => item._id !== id);
        setProperties(newProperties);
        onCloseDeleteModal();
        changeContinuePopup('Property deleted successfully');
        setTimeout(() => {
          changeContinuePopup('Property deleted successfully');
        }, 3000);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <Seo title='Properties' />
      <Box className={classes.titleWrapper}>
        <h1> Property Posts </h1>
        {changingConfirmed && (
          <ChangeConfirmedPopup
            successText={successText}
            changingConfirmed={changingConfirmed}
          />
        )}
      </Box>

      <Table
        loading={isLoading || !properties}
        rows={(properties && properties) || []}
        columns={columns}
        getRowId={(row) => row._id}
        className={classes.tableWrapper}
      />

      {openDeleteModal.show && (
        <DeletePopUp
          openDeleteModal={openDeleteModal}
          onCloseDeleteModal={onCloseDeleteModal}
          onDeleteProperties={onDeleteProperties}
        />
      )}
    </Box>
  );
};

export default AllProperties;
