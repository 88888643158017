import { get, post, put, remove } from './api';

export const createProperty = (body: Record<string, any>) =>
  post({
    url: '/admin/property',
    body,
  });

export const getProperties = () => get({ url: '/admin/properties' });

export const getPropertyBySlug = (slug: string) =>
  get({ url: `/property/${slug}` });

export const getSuggetedTags = (prefix: string) =>
  get({ url: `/property/tags/${prefix}` });

export const updateProperty = (id: string, body: Record<string, any>) =>
  put({ url: `/admin/property/${id}`, body });

export const deleteProperty = (id: string) =>
  remove({ url: `/admin/property/${id}` });
