import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Seo } from 'src/constants';
import { login, register, dashboard } from 'src/utils/routing';
import { useDispatch } from 'react-redux';
import { setIsLoginUser, setUserData } from 'src/mainstore/features/auth/auth';
import { FormikValues } from 'formik';
import * as service from 'src/service';
import Logo from 'src/assets/logo4.png';
import Keys from 'src/constants/helper';
import SignUp from './SignUp';
import Login from './Login';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';
import cx from 'classnames';
import { useStyles } from './RegisterStyles';

const Register: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(
    () => new URLSearchParams(location.search).get('type') ?? 'login'
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate(dashboard);
    }
  }, [navigate]);

  useEffect(() => {
    let activePage = new URLSearchParams(location.search).get('type');
    if (activePage !== 'login' && activePage !== 'sign-up') {
      navigate(login);
    } else if (activePage) {
      setActiveTab(activePage);
    } else {
      setActiveTab('login');
    }
  }, [location.search, navigate]);

  const onLogin = (values: FormikValues, actions: any) => {
    setIsLoading(true);
    service.auth
      .login(values)
      .then((res) => {
        if (!res.response.ok) throw res;
        return res;
      })
      .then((res) => {
        if (res.response.status === 200) {
          const { token, user } = res.data;
          localStorage.setItem(Keys.JWT_TOKEN, token);
          dispatch(setIsLoginUser());
          dispatch(setUserData({ ...user, token }));
          navigate(dashboard);
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        actions.setFieldError(
          'email',
          err.response.status === 401
            ? 'Invalid email or password'
            : 'Something went wrong'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const onSignUp = (values: FormikValues, actions: any) => {
    let userInfo = values;
    setIsLoading(true);
    service.auth
      .register(values)
      .then((res) => {
        if (!res.response.ok) throw res;
        return res;
      })
      .then((res) => {
        if (res.response.status === 200) {
          const { token } = res.data;
          userInfo = { ...userInfo, token };
          setIsLoading(false);
          localStorage.setItem(Keys.JWT_TOKEN, token);
          dispatch(setIsLoginUser());
          navigate(dashboard);
          return dispatch(setUserData(userInfo));
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        actions.setFieldError(
          'email',
          err.response.status === 400
            ? 'Email already used'
            : 'Something went wrong'
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Seo title='Register' />
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        spacing={2}
        height='100vh'
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent sx={{ p: 0 }}>
              <Box className={classes.logoWrapper}>
                <img src={Logo} alt='logo' className={classes.logo} />
              </Box>
              <Box className={classes.registerWrapper}>
                <Typography
                  className={cx(activeTab === 'login' && classes.activeTab)}
                  onClick={() => navigate(login)}
                  variant='h4'
                  component='h1'
                  gutterBottom
                >
                  Log In
                </Typography>
                <Typography
                  className={cx(activeTab === 'sign-up' && classes.activeTab)}
                  onClick={() => navigate(register)}
                  variant='h4'
                  component='h1'
                  gutterBottom
                >
                  Registration
                </Typography>
              </Box>
              {activeTab === 'login' ? (
                <Login isLoading={isLoading} onLogin={onLogin} />
              ) : (
                <SignUp isLoading={isLoading} onSignUp={onSignUp} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Register;
