import React, { FC, memo } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

interface SelectFieldProps {
  value: string[] | string;
  onChange: SelectInputProps['onChange'];
  options: {
    _id: string;
    value: string;
  }[];

  className?: string;
  inputProps?: any;
  placeholder?: string;
  displayEmpty?: boolean;
  sx?: Record<string, any>;
  name?: string;
  onBlur?: SelectInputProps['onBlur'];
}
const SelectField: FC<SelectFieldProps> = ({
  value,
  onChange,
  sx,
  options,
  className,
  inputProps,
  placeholder,
  displayEmpty,
  name,
  onBlur,
}) => {
  return (
    <FormControl sx={sx}>
      <Select
        value={value}
        onChange={onChange}
        className={className}
        inputProps={inputProps}
        displayEmpty={displayEmpty ? true : false}
        name={name}
        onBlur={onBlur}
      >
        <MenuItem value=''>
          <em style={{ color: 'grey' }}>{placeholder}</em>
        </MenuItem>

        {options.map((option, index) => (
          <MenuItem key={index} value={option._id}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(SelectField);
