import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as service from 'src/service';
import { Seo } from 'src/constants';
import LeftSide from './ProfileFields/LeftSide';
import RightSide from './ProfileFields/RightSide';
import { useBottomPopup, ChangeConfirmedPopup } from 'src/components';
import { Form, Formik, FormikValues } from 'formik';
import { updateProfileValidationSchema } from '../utils/validation';
import { updateProfileInitialValues } from '../utils/values';
import PasswordPopUp from './PopUp/PasswordPopUp';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useToggle } from 'src/components';
import { useStyles } from './ProfileStyles';

const Profile: FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const isMobile = useMediaQuery('(max-width: 324px)');

  const [openPasswordModal, setOpenPasswordModal] = useToggle(false);
  const [form, setForm] = useState<FormikValues>(
    () => updateProfileInitialValues
  );
  const [, setIsLoading] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>('');
  const [preview, setPreview] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsLoading(true);
    service.profile
      .getCurrentUser()
      .then((res) => {
        setIsLoading(false);
        setForm((prevState) => ({ ...prevState, ...res.data.user }));
        setPreview(res.data.user.thumbnail.url);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  const onOpenPasswordModal = () => setOpenPasswordModal.on();
  const onClosePasswordModal = () => setOpenPasswordModal.off();

  const onFocused = () => {
    setIsFocused(true);
    inputRef.current?.focus();
  };

  const onCancel = useCallback(() => {
    setIsFocused(false);
    navigate(0);
  }, [navigate]);

  const onChangeThumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreview(reader.result as string);
        setAvatar(reader.result as string);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onSavedInfo = (values: FormikValues) => {
    setIsLoading(true);
    service.profile
      .updateProfile(values)
      .then((res) => {
        setIsLoading(false);
        if (res.response.status === 200) {
          setForm((prevState) => ({ ...prevState, ...res.data.user }));
          changeContinuePopup('Profile updated successfully');
          setTimeout(() => {
            changeContinuePopup('Profile updated successfully');
          }, 3000);

          setIsFocused(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onSavedPassword = (values: FormikValues) => {
    setIsLoading(true);
    service.profile
      .updatePassword(values)
      .then((res) => {
        setIsLoading(false);
        if (res.response.status === 200) {
          changeContinuePopup('Password updated successfully');
          setTimeout(() => {
            changeContinuePopup('Password updated successfully');
          }, 3000);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {
        onClosePasswordModal();
      });
  };

  return (
    <Box>
      <Seo title='Profile' />
      <Box className={classes.titleWrapper}>
        <h1>Profile</h1>
        {changingConfirmed && (
          <ChangeConfirmedPopup
            successText={successText}
            changingConfirmed={changingConfirmed}
          />
        )}
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={form}
        validationSchema={updateProfileValidationSchema}
        onSubmit={async (values, actions) => {
          await onSavedInfo(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          handleSubmit,

          values,
          handleChange,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <LeftSide
                avatar={avatar}
                preview={preview}
                onChangeThumbnail={onChangeThumbnail}
                isMobile={isMobile}
              />
              <RightSide
                onOpenPasswordModal={onOpenPasswordModal}
                onCancel={onCancel}
                isFocused={isFocused}
                onFocused={onFocused}
                values={values}
                handleChange={handleChange}
              />
            </Grid>
          </Form>
        )}
      </Formik>

      {openPasswordModal && (
        <PasswordPopUp
          onOpenPasswordModal={openPasswordModal}
          onClosePasswordModal={onClosePasswordModal}
          onSavedPassword={onSavedPassword}
        />
      )}
    </Box>
  );
};

export default Profile;
