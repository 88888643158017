import React, { FC, ChangeEventHandler, FocusEventHandler, memo } from 'react';
import { useField } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export type InputFieldProps = {
  id?: HTMLInputElement['id'];
  name?: HTMLInputElement['name'];
  value?: HTMLInputElement['value'];
  type?: HTMLInputElement['type'];
  placeholder?: HTMLInputElement['placeholder'];
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: HTMLInputElement['className'];
  disabled?: HTMLInputElement['disabled'];
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  ref?: React.Ref<HTMLInputElement>;
} & TextFieldProps;

export const InputField: FC<InputFieldProps> = ({
  id,
  name,
  value = '',
  placeholder = '',
  type = 'text',
  onChange,
  onFocus,
  onBlur,
  className,
  disabled,
  onClick,
  onKeyDown,
  ref,
  ...props
}) => {
  const [field, meta] = useField(name || '');
  return (
    <>
      <TextField
        {...props}
        {...field}
        id={id}
        data-testid='input-field'
        autoComplete='off'
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={className}
        disabled={disabled}
        onClick={onClick}
        onKeyDown={onKeyDown}
        ref={ref}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    </>
  );
};

export default memo(InputField);
