import { get, put, remove, post } from './api';

export const getCurrentUser = () => get({ url: '/me' });

export const updateProfile = (body: Record<string, any>) =>
  put({ url: '/me/update', body });

export const updatePassword = (body: Record<string, any>) =>
  put({ url: '/password/update', body });

export const getAllUsers = () => get({ url: '/admin/users' });

export const getUser = (id: string) => get({ url: `/admin/user/${id}` });

export const updateUser = (id: string, body: Record<string, any>) =>
  put({ url: `/admin/user/${id}`, body });

export const deleteUser = (id: string) => remove({ url: `/admin/user/${id}` });

export const createUser = (body: Record<string, any>) =>
  post({ url: '/create-user-account', body });
