import { get, post, remove } from './api';

export const createBanner = (body: Record<string, any>) =>
  post({
    url: '/admin/banner',
    body,
  });

export const getBanners = () => get({ url: '/admin/banners' });

export const deleteBanner = (id: string) =>
  remove({ url: `/admin/banner/${id}` });
