import { FC } from 'react';
import { Modals } from 'src/components';
import { ActionButton } from 'src/components/Forms';
import Logo from 'src/assets/logo4.png';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../AllPropertiesStyles';

interface IDeletePopUp {
  openDeleteModal: {
    show: boolean;
    data: string;
  };
  onCloseDeleteModal: () => void;
  onDeleteProperties: (id: string) => void;
}

const DeletePopUp: FC<IDeletePopUp> = ({
  openDeleteModal,
  onCloseDeleteModal,
  onDeleteProperties,
}) => {
  const classes = useStyles();
  const { palette } = useTheme();
  return (
    <Modals openModal={openDeleteModal.show} closeModal={onCloseDeleteModal}>
      <Card className={classes.modalContent}>
        <CardContent sx={{ p: 0 }}>
          <Box className={classes.modalHeader}>
            <img src={Logo} alt='logo' className={classes.logo} />
            <IconButton onClick={onCloseDeleteModal}>
              <CloseIcon sx={{ color: palette.primary[500] }} />
            </IconButton>
          </Box>
          <Box className={classes.modalWrapper}>
            <Typography className={classes.modalWarningMessage}>
              Are you sure you want to delete this property?
            </Typography>

            <ActionButton
              onClick={onCloseDeleteModal}
              className={classes.modalCancel}
            >
              Cancel
            </ActionButton>

            <ActionButton
              onClick={() => onDeleteProperties(openDeleteModal.data)}
              className={classes.modalBtnDelete}
            >
              Delete
            </ActionButton>
          </Box>
        </CardContent>
      </Card>
    </Modals>
  );
};

export default DeletePopUp;
