import React, { FC, useEffect, useState } from 'react';
import * as service from 'src/service';
import { Seo } from 'src/constants';
import { routing } from 'src/utils';
import moment from 'moment';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { StatBox } from 'src/components';
import { PersonAdd } from '@mui/icons-material';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import WebIcon from '@mui/icons-material/Web';

const Dashboard: FC = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery('(min-width: 1200px)');
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalBlogs, setTotalBlogs] = useState([]);
  const [totalProperties, setTotalProperties] = useState([]);
  const [totalLandMarks, setTotalLandMarks] = useState([]);

  useEffect(() => {
    Promise.all([
      service.profile.getAllUsers(),
      service.blog.getBlogs(),
      service.property.getProperties(),
      service.categories_property.getCategories(),
    ])
      .then((res) => {
        setTotalUsers(res[0].data.users);
        setTotalBlogs(res[1].data.blogs);
        setTotalProperties(res[2].data.properties);
        setTotalLandMarks(res[3].data.categories);
      })
      .catch(console.error);
  }, []);

  return (
    <Box m='1.5rem 0'>
      <Seo title='Dashboard' />
      <h1>Dashboard</h1>
      <Box
        mt='20px'
        display='grid'
        gridTemplateColumns='repeat(2, 1fr)'
        gridAutoRows='160px'
        gap='20px'
        sx={{
          '& > div': { gridColumn: isNonMediumScreens ? undefined : 'span 12' },
        }}
      >
        <StatBox
          title='Total Users'
          value={totalUsers?.length}
          description={`Since ${moment().subtract(1, 'months').format('MMMM')}`}
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: '26px' }}
            />
          }
          path={routing.users}
        />
        <StatBox
          title='Total Properties'
          value={totalProperties?.length}
          description={`Since ${moment().subtract(1, 'months').format('MMMM')}`}
          icon={
            <InventoryIcon
              sx={{ color: theme.palette.secondary[300], fontSize: '26px' }}
            />
          }
          path={routing.property}
        />

        <StatBox
          title='Total Blogs'
          value={totalBlogs?.length}
          description={`Since ${moment().subtract(1, 'months').format('MMMM')}`}
          icon={
            <WebIcon
              sx={{ color: theme.palette.secondary[300], fontSize: '26px' }}
            />
          }
          path={routing.blog}
        />

        <StatBox
          title='Total LandMark'
          value={totalLandMarks?.length}
          description={`Since ${moment().subtract(1, 'months').format('MMMM')}`}
          icon={
            <CategoryIcon
              sx={{ color: theme.palette.secondary[300], fontSize: '26px' }}
            />
          }
          path={routing.categories}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
