import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import cx from 'classnames';
import { useStyles } from './TableStyles';

interface ITable {
  columns: GridColDef[];
  rows: Record<string, any>[];
  getRowId?: (row: Record<string, any>) => string;
  loading?: boolean;
  className?: string;
}

const Table: FC<ITable> = ({ columns, rows, getRowId, loading, className }) => {
  const classes = useStyles();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  return (
    <Box
      gridColumn='span 8'
      gridRow='span 3'
      className={cx(classes.table, className)}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );
};

export default Table;
