export const selectMode = (state: { [key: string]: any }) => state.mode.mode;
export const createCategory = (state: Record<string, any>) =>
  state.category.category;
export const selectStatus = (state: Record<string, any>) =>
  state.category.status;
export const selectError = (state: Record<string, any>) => state.category.error;
export const getAllCategories = (state: any) =>
  state.blogCategory.categories.categories;

export const user = (state: Record<string, any>) => state?.auth?.user;
export const isLogin = (state: Record<string, any>) => state?.auth?.isLogin;

export const getAccessToken = (state: Record<string, any>) =>
  state?.auth?.token;

export const getUserId = (state: Record<string, any>) => state?.auth?.user._id;

export const getUserName = (state: Record<string, any>) =>
  state?.auth?.user?.name;

export const getUserRole = (state: Record<string, any>) =>
  state?.auth?.user?.role;

export const getUserEmail = (state: Record<string, any>) =>
  state.auth.user.email;
