import { FC } from 'react';
import { Modals } from 'src/components';
import Logo from 'src/assets/logo4.png';
import { InputField, ActionButton } from 'src/components/Forms';
import { Box, Card, CardContent, useTheme, IconButton } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { propertyCategoryValidationSchema } from '../../utils/validation';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../PropertiesCategoryStyles';

interface IEditPopUp {
  onOpenEditModal: {
    show: boolean;
    data: {
      _id: string;
      name: string;
    };
  };
  onCloseEditModal: () => void;
  onSaveCategory: (values: FormikValues, actions: any) => void;
}
const EditPopUp: FC<IEditPopUp> = ({
  onOpenEditModal,
  onCloseEditModal,
  onSaveCategory,
}) => {
  const classes = useStyles();
  const { palette } = useTheme();
  return (
    <Modals openModal={onOpenEditModal.show} closeModal={onCloseEditModal}>
      <Formik
        initialValues={{
          name: onOpenEditModal.data.name,
        }}
        validationSchema={propertyCategoryValidationSchema}
        onSubmit={onSaveCategory}
      >
        {({ handleSubmit, isSubmitting, values, handleChange, handleBlur }) => (
          <Form onSubmit={handleSubmit}>
            <Card className={classes.modalContent}>
              <CardContent sx={{ p: 0 }}>
                <Box className={classes.modalHeader}>
                  <img src={Logo} alt='logo' className={classes.logo} />
                  <IconButton onClick={onCloseEditModal}>
                    <CloseIcon sx={{ color: palette.primary[500] }} />
                  </IconButton>
                </Box>
                <Box className={classes.modalWrapper}>
                  <Box className={classes.inputWrapper}>
                    <InputField
                      className={classes.inputField}
                      name='name'
                      placeholder='enter category name...'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Box>
                  <ActionButton
                    onClick={onCloseEditModal}
                    className={classes.modalCancel}
                  >
                    Cancel
                  </ActionButton>

                  <ActionButton
                    type='submit'
                    className={classes.modalBtnDelete}
                    disabled={isSubmitting}
                  >
                    Save
                  </ActionButton>
                </Box>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </Modals>
  );
};

export default EditPopUp;
