import React, { FC, useEffect, useState } from 'react';
import { Loader } from 'src/components';
import { ActionButton } from 'src/components/Forms';
import * as service from 'src/service';
import cx from 'classnames';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Box, Avatar, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useStyles } from '../ProfileStyles';

interface LeftSideProps {
  avatar: string;
  preview: string;
  onChangeThumbnail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isMobile: boolean;
}
const Input = styled('input')({
  display: 'none',
});

const LeftSide: FC<LeftSideProps> = ({
  avatar,
  preview,
  onChangeThumbnail,
  isMobile,
}) => {
  const classes = useStyles();
  const [getAvatar, setGetAvatar] = useState<string>('');
  const [getAvatarId, setAvatarId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    service.avatar
      .getAvatar()
      .then((res) => {
        setGetAvatar(res.data.avatar.avatar.url);
        setAvatarId(res.data.avatar._id);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSaveAvatar = () => {
    const formData = new FormData();
    formData.append('avatar', avatar);
    const data = Object.fromEntries(formData.entries());
    if (getAvatar) {
      service.avatar
        .updateAvatar(getAvatarId, data)
        .then((res) => {
          console.log(res);
          return setGetAvatar(res.data.avatar.url);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      service.avatar
        .createAvatar(data)
        .then((res) => {
          console.log(res);
          setGetAvatar(res.data.avatar.url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const removeAvatar = () => {
    service.avatar
      .deleteAvatar(getAvatarId)
      .then((res) => {
        setGetAvatar('');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid item xs={12} sm={12} md={5} className={classes.leftGrid}>
      {!avatar && !getAvatar && (
        <Box>
          <Avatar
            className={cx(classes.avatar, { [classes.avatarMobile]: isMobile })}
            alt='Remy Sharp'
            src='/static/images/avatar/1.jpg'
          />
        </Box>
      )}

      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{ mb: 3, position: 'relative' }}
      >
        {isLoading ? (
          <Loader />
        ) : preview ? (
          <img src={preview} alt='thumbnail' className={classes.thumbnailImg} />
        ) : getAvatar ? (
          <img src={getAvatar} alt='avatar' className={classes.thumbnailImg} />
        ) : null}

        {getAvatar && hover && !preview && (
          <Box className={classes.deleteWrapper}>
            <DeleteIcon onClick={removeAvatar} className={classes.btnDelete} />
          </Box>
        )}
      </Box>

      <Box className={classes.avatarWrapper}>
        <label htmlFor='contained-button-file'>
          <Input
            accept='image/*'
            id='contained-button-file'
            multiple
            type='file'
            name='avatar'
            onChange={onChangeThumbnail}
          />
          <Button
            className={classes.btnUpload}
            variant='contained'
            component='span'
          >
            Upload Avatar
          </Button>
        </label>
        <ActionButton
          className={classes.btnSaveAvatar}
          type='button'
          onClick={onSaveAvatar}
        >
          {!getAvatar ? 'Save Avatar' : 'Update Avatar'}
        </ActionButton>
      </Box>
    </Grid>
  );
};

export default LeftSide;
