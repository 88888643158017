import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMode } from '../../mainstore/features/mode/modeSlice';
import {
  setLogoutUser,
  setIsLoginUser,
} from '../../mainstore/features/auth/auth';
import * as service from '../../service';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  Avatar,
} from '@mui/material';
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  ArrowDropDownOutlined,
} from '@mui/icons-material';
import { useStyles } from './NavBarStyles';

interface INavBar {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
}
const NavBar: FC<INavBar> = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [getAvatar, setAvatar] = useState<string>('');

  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    service.avatar
      .getAvatar()
      .then((res) => {
        setAvatar(res.data.avatar.avatar.url);
      })
      .catch(console.error);
  }, []);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onLogout = () => {
    service.auth.logout().then((res) => {
      if (res.response.status === 200) {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(setIsLoginUser());
        dispatch(setLogoutUser());
        navigate('/auth?type=login');
      }
    });
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box className={classes.bugerWrapper}>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
        </Box>

        <Box className={classes.usersInfoWrapper}>
          <IconButton onClick={() => dispatch(setMode())}>
            {palette.mode === 'dark' ? (
              <DarkModeOutlined sx={{ fontSize: '25px' }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: '25px' }} />
            )}
          </IconButton>
          {getAvatar ? (
            <img src={getAvatar} alt='avatar' className={classes.avatar} />
          ) : (
            <Avatar alt='Remy Sharp' src='/static/images/avatar/1.jpg' />
          )}

          <IconButton onClick={handleClick}>
            <ArrowDropDownOutlined
              sx={{ color: palette.secondary[300], fontSize: '25px' }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <MenuItem onClick={onLogout}>Log Out</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
