import React, { FC } from 'react';
import { Modals } from 'src/components';
import { InputField, ActionButton, InputLabel } from 'src/components/Forms';
import { Form, Formik, FormikValues } from 'formik';
import { changePasswordValidationSchema } from '../../utils/validation';
import { changePasswordInitialValues } from '../../utils/values';
import Logo from 'src/assets/logo4.png';
import { Box, Card, CardContent, useTheme, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../ProfileStyles';

interface IEditPopUp {
  onOpenPasswordModal: boolean;
  onClosePasswordModal: () => void;
  onSavedPassword: (values: FormikValues) => void;
}
const PasswordPopUp: FC<IEditPopUp> = ({
  onOpenPasswordModal,
  onClosePasswordModal,
  onSavedPassword,
}) => {
  const classes = useStyles();
  const { palette } = useTheme();

  return (
    <Modals openModal={onOpenPasswordModal} closeModal={onClosePasswordModal}>
      <Formik
        initialValues={changePasswordInitialValues}
        validationSchema={changePasswordValidationSchema}
        onSubmit={async (values, actions) => {
          onSavedPassword(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          values,
          handleChange,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Card className={classes.modalContent}>
              <CardContent sx={{ p: 0 }}>
                <Box className={classes.modalHeader}>
                  <img src={Logo} alt='logo' className={classes.logo} />
                  <IconButton onClick={onClosePasswordModal}>
                    <CloseIcon sx={{ color: palette.primary[500] }} />
                  </IconButton>
                </Box>

                <Box className={classes.modalWrapper}>
                  <Box className={classes.inputWrapper}>
                    <InputLabel id='old password' title=' Old Password' />
                    <InputField
                      className={classes.inputField}
                      value={values.oldPassword}
                      onChange={handleChange}
                      name='oldPassword'
                      placeholder='Old Password'
                      type='password'
                    />
                  </Box>

                  <Box className={classes.inputWrapper}>
                    <InputLabel id='password' title=' New Password' />
                    <InputField
                      className={classes.inputField}
                      name='newPassword'
                      value={values.newPassword}
                      onChange={handleChange}
                      placeholder='New Password'
                      type='password'
                    />
                  </Box>

                  <Box className={classes.inputWrapper}>
                    <InputLabel
                      id='confirm password'
                      title='Confirm Password'
                    />
                    <InputField
                      className={classes.inputField}
                      name='confirmPassword'
                      type='password'
                      placeholder='Confirm Password'
                      value={values.confirmPassword}
                      onChange={handleChange}
                    />
                  </Box>

                  <ActionButton
                    onClick={onClosePasswordModal}
                    className={classes.modalCancel}
                  >
                    Cancel
                  </ActionButton>

                  <ActionButton
                    disabled={!isValid || !dirty || isSubmitting}
                    type='submit'
                    className={classes.modalBtnDelete}
                  >
                    Save
                  </ActionButton>
                </Box>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </Modals>
  );
};

export default PasswordPopUp;
