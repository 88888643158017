import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: any) => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    marginBottom: '1rem',
  },

  input: {
    width: '300px',
  },

  leftGrid: {
    borderRight: '1px solid  #E5E5E5',
    [theme.breakpoints.down('md')]: {
      borderRight: 'none',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  avatar: {
    height: '300px',
    width: '300px',
    marginBottom: '3rem',
  },

  avatarMobile: {
    height: '200px',
    width: '200px',
  },

  rightGrid: {
    paddingLeft: '6rem',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'unset',
    },
  },

  btnSubmit: {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnModify: {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  avatarWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
  },

  btnUpload: {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnSaveAvatar: {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  imageWrapper: {
    marginBottom: '1rem',
  },

  thumbnailImg: {
    objectFit: 'cover',
    height: '300px',
    width: '300px',
    borderRadius: '50%',
  },

  inputField: {
    width: '100%',
  },

  inputPassword: {
    marginBottom: '2.7rem',
  },

  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },

  btnSave: {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnCancel: {
    backgroundColor: theme.palette.secondary[300],
    color: theme.palette.primary[700],
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.secondary[300],
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary[300],
    padding: '0.7rem',
    marginBottom: '2rem',
  },

  logo: {
    width: '100px',
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: '0.5s',
    borderRadius: '0.4rem',
    width: '428px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  modalCancelBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
  },

  modalDelete: {
    display: 'flex',
    alignItems: 'center',
  },
  modalWrapper: {
    padding: ' 0 1rem',
  },

  deleteBtnAccount: {
    marginLeft: '0.5rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },

  modalWarningMessage: {
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '2rem',
    marginBottom: '0.5rem',
  },

  cancelPointer: {
    cursor: 'pointer',
  },

  modalCancel: {
    display: 'block',
    width: '100%',
    color: theme.palette.primary[700],
    background: theme.palette.secondary[300],
    borderRadius: '0.6rem',
    padding: '0.6rem 0',
    boxShadow: 'none',
    marginBottom: '1rem',
    textTransform: 'capitalize',
    fontSize: '1rem',
    '&:hover': {
      background: theme.palette.secondary[300],
      boxShadow: 'none',
      opacity: '0.8',
    },
  },

  modalBtnDelete: {
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0.5rem',
    boxShadow: 'none',
    padding: '0.6rem 0',
    textTransform: 'capitalize',
    fontSize: '1rem',
    margin: 0,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      opacity: '0.8',
    },
  },

  deleteWrapper: {
    position: 'absolute',
    top: '.5rem',
    left: '50%',
  },

  btnDelete: {
    color: theme.palette.secondary[300],
    cursor: 'pointer',
  },
}));
