import { FC } from 'react';
import { Helmet } from 'react-helmet';

interface ISeo {
  title: string;
  text?: string;
  link?: string;
}

const Seo: FC<ISeo> = ({ title, text = '', link = '' }) => {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{title}</title>
      <meta name='description' content={text} />
    </Helmet>
  );
};

export default Seo;
