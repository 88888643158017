import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    width: '100%',
    border: 'none',
  },

  tagContent: {
    padding: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.primary[300]}`,
    borderRadius: '8px',
    height: 'fit-content',
    position: 'relative',
    transition: '.2s all',
    margin: '.6rem 0',
  },

  tagWrapper: {
    padding: '3px 10px',
    border: `1px solid ${theme.palette.primary[300]}`,
    background: theme.palette.background.paper,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '3px 5px',
    position: 'relative',
  },

  removeTag: {
    fontSize: '20px',
    marginTop: '1px',
    color: theme.palette.primary[300],
    cursor: 'pointer',
    paddingLeft: '12px',
  },
  suggestionWrapper: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary[300]}`,
    padding: '15px 7px',
    position: 'absolute',
    top: 0,
    left: 0,
    height: 'fit-content',
    width: '100%',
    boxShadow: '0 2px 8px rgba(47, 73, 209, 0.16)',
    borderRadius: '8px',
    zIndex: 20,
  },

  tagsItems: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '8px',
  },

  tagSuggestions: {
    cursor: 'pointer',
    padding: '5px 0',
    border: `1px solid ${theme.palette.primary[300]}`,
    margin: '5px 0',
    borderRadius: '8px',
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    transition: '.2s',
    '&:hover': {
      background: theme.palette.primary[300],
      color: theme.palette.background.paper,
    },
  },
  textSuggestion: {
    display: 'block',
    fontWeight: '600',
    fontsize: '18px',
    lineHeight: '27px',
    color: theme.palette.text.primary,
    marginBottom: '10px',
  },
}));
