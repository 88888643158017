import React, { FC, useState, useEffect } from 'react';
import { InputField, ActionButton } from 'src/components/Forms';
import { Seo } from 'src/constants';
import { Table, ChangeConfirmedPopup, useBottomPopup } from 'src/components';
import DeletePopUp from './PopUp/DeletePopUp';
import EditPopUp from './PopUp/EditPopUp';
import * as service from 'src/service';
import { Box, useTheme, IconButton } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { blogCategoryValidationSchema } from '../utils/validation';
import { blogCategoryInitialValues } from '../utils/values';
import { IModalDisplay, IModalDisplayEdit } from './utils/types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useStyles } from './BlogCategoryStyles';

interface IBlogCategory {
  _id: string;
  name: string;
}

const BlogCategory: FC = () => {
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const { palette } = useTheme();
  const classes = useStyles();
  const [categories, setCategories] = useState<IBlogCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<IModalDisplayEdit>({
    show: false,
    data: {
      _id: '',
      name: '',
    },
  });
  const [openDeleteModal, setOpenDeleteModal] = useState<IModalDisplay>({
    show: false,
    data: '',
  });

  useEffect(() => {
    setIsLoading(true);
    service.categories_blog
      .getCategories()
      .then((res) => {
        setCategories(res.data.categories as unknown as IBlogCategory[]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }, [changingConfirmed]);

  const onOpenEditModal = (data: { _id: string; name: string }) => {
    setOpenEditModal((prevState) => ({
      show: !prevState.show,
      data: { ...data },
    }));
  };

  const onCloseEditModal = () => {
    setOpenEditModal((prevState) => ({
      show: !prevState.show,
      data: { _id: '', name: '' },
    }));
  };

  const onOpenDeleteModal = (data: string) => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data,
    }));
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data: '',
    }));
  };

  const columns: GridColDef[] = [
    {
      field: '_id',
      headerName: 'ID',
      width: 300,
      sortable: false,
    },
    {
      field: 'name',
      headerName: ' Name',
      width: 200,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', gap: '0.7rem' }}>
          <>
            <IconButton onClick={() => onOpenEditModal(params.row)}>
              <EditIcon style={{ color: palette.secondary[500] }} />
            </IconButton>
            <IconButton onClick={() => onOpenDeleteModal(params.row._id)}>
              <DeleteIcon style={{ color: palette.primary[200] }} />
            </IconButton>
          </>
        </Box>
      ),
    },
  ];

  const onCategorySubmit = (values: FormikValues, actions: any) => {
    service.categories_blog
      .createCategory(values)
      .then((res) => {
        console.log(res);
        changeContinuePopup('Category created successfully');
        actions.resetForm({
          values: {
            name: '',
          },
        });
        setTimeout(() => {
          changeContinuePopup('Category created successfully');
        }, 3000);
        return res;
      })
      .catch((error: any) => {
        actions.setSubmitting(false);
        actions.setFieldError('name', error.message);
      });
  };

  const onSaveCategory = (values: FormikValues, actions: any) => {
    const id = openEditModal.data._id;
    service.categories_blog
      .updateCategory(id, values)
      .then((res) => {
        console.log(res);
        setOpenEditModal((prevState) => ({
          show: !prevState.show,
          data: { _id: '', name: '' },
        }));
        changeContinuePopup('Category updated successfully');
        actions.resetForm({
          values: {
            name: '',
          },
        });
        setTimeout(() => {
          changeContinuePopup('Category updated successfully');
        }, 3000);
      })
      .catch((error: any) => {
        actions.setSubmitting(false);
        actions.setFieldError('name', error.message);
      });
  };

  const onDeleteCategory = (id: string) => {
    service.categories_blog
      .deleteCategory(id)
      .then((res) => {
        console.log(res);
        setOpenDeleteModal((prevState) => ({
          show: !prevState.show,
          data: '',
        }));
        changeContinuePopup('Category deleted successfully');
        setTimeout(() => {
          changeContinuePopup('Category deleted successfully');
        }, 3000);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  return (
    <Box>
      <Seo title='Create Category' />
      <Box className={classes.titleWrapper}>
        <h1> Create Categories</h1>
        {changingConfirmed && (
          <ChangeConfirmedPopup
            successText={successText}
            changingConfirmed={changingConfirmed}
          />
        )}
      </Box>

      <Formik
        initialValues={blogCategoryInitialValues}
        validationSchema={blogCategoryValidationSchema}
        onSubmit={onCategorySubmit}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          values,
          handleChange,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.inputWrapper}>
              <InputField
                name='name'
                placeholder='enter category name...'
                onChange={handleChange}
                value={values.name}
              />
              <ActionButton
                disabled={!isValid || isSubmitting || !dirty}
                className={classes.btnSubmit}
                type='submit'
              >
                Create
              </ActionButton>
            </Box>
            <Table
              loading={isLoading || !categories}
              rows={(categories && categories) || []}
              columns={columns}
              getRowId={(row) => row._id}
              className={classes.tableWrapper}
            />
          </Form>
        )}
      </Formik>
      {openEditModal.show && (
        <EditPopUp
          onOpenEditModal={openEditModal}
          onCloseEditModal={onCloseEditModal}
          onSaveCategory={onSaveCategory}
        />
      )}
      {openDeleteModal.show && (
        <DeletePopUp
          openDeleteModal={openDeleteModal}
          onCloseDeleteModal={onCloseDeleteModal}
          onDeleteCategory={onDeleteCategory}
        />
      )}
    </Box>
  );
};

export default BlogCategory;
