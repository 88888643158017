import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: any) => ({
  table: {
    height: 400,
    width: '100%',
    '& .MuiDataGrid-root': {
      border: 'none',
      borderRadius: '5rem',
    },
    // '& .MuiDataGrid-cell': {
    //   borderBottom: 'none',
    // },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary[100],
      //   borderBottom: 'none',
    },
    '& .MuiDataGrid-virtualScroller': {
      backgroundColor: theme.palette.background.paper,

      '&::-webkit-scrollbar': {
        width: '7px',
      },

      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
      },

      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary[300],
        borderRadius: '10px',
        backgroundClip: 'content-box',
      },
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary[100],
      borderTop: 'none',
    },
    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
      color: `${theme.palette.secondary[200]} !important`,
    },
  },
}));
