import React, { FC, useState } from 'react';
import {
  InputField,
  TextAreaField,
  SelectField,
  InputLabel,
} from 'src/components/Forms';
import { FormikValues } from 'formik';
import { styled } from '@mui/material/styles';
import { countries, cities } from '../data/countries';
import { Box, Button, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useStyles } from '../PropertiesStyles';

const Input = styled('input')({
  display: 'none',
});

interface IRightSIdeFields {
  onRemoveImage: (index: number) => void;
  imagesPreview: string[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  values: FormikValues;
  setFieldValue: (field: string, value: any) => void;
  setImagesPreview: React.Dispatch<React.SetStateAction<string[]>>;
  setPropertyImage: React.Dispatch<React.SetStateAction<string[]>>;
  propertyImage: string[];
  errors: { [key: string]: any };
  touched: { [key: string]: any };
}

const RightSideFields: FC<IRightSIdeFields> = ({
  imagesPreview,
  handleChange,
  values,
  setFieldValue,
  setImagesPreview,
  setPropertyImage,
  errors,
  touched,
  onRemoveImage,
  handleBlur,
}) => {
  const classes = useStyles();
  const [hover, setHover] = useState<{ [key: number]: boolean }>({});

  const onMouseOver = (index: number) => {
    setHover((prev) => ({ ...prev, [index]: true }));
  };

  const onMouseOut = (index: number) => {
    setHover((prev) => ({ ...prev, [index]: false }));
  };

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Box>
        <label htmlFor='contained-button-file'>
          <Input
            accept='image/*'
            id='contained-button-file'
            multiple
            type='file'
            name='propertyImage'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const files = Array.from(e.target.files as FileList);
              files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = () => {
                  if (reader.readyState === 2) {
                    setImagesPreview((prev: any) => [...prev, reader.result]);
                    setPropertyImage((old: any) => [...old, reader.result]);
                  }
                };
                reader.readAsDataURL(file);
              });
            }}
          />
          <Button
            className={classes.btnUpload}
            variant='contained'
            component='span'
          >
            Upload property images
          </Button>
        </label>
      </Box>

      <Grid container spacing={{ xs: 1, md: 1 }}>
        {imagesPreview.map((image, index) => (
          <Grid
            item
            xs={2}
            sm={4}
            md={3}
            key={index}
            className={classes.gridImage}
            onMouseOver={() => onMouseOver(index)}
            onMouseOut={() => onMouseOut(index)}
          >
            <img
              src={image}
              alt='Product Preview'
              className={classes.imageSizeThumb}
            />
            {hover[index] && (
              <IconButton className={classes.trash}>
                <DeleteIcon onClick={() => onRemoveImage(index)} />
              </IconButton>
            )}
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='property street' title=' Property Street' />
            <InputField
              name='propertyAddress.street'
              value={values.propertyAddress.street}
              placeholder='property street...'
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='state' title=' State' />
            <SelectField
              value={values.propertyAddress.city}
              options={cities.map((cities) => ({
                _id: cities.name,
                value: cities.name,
              }))}
              onChange={(e) =>
                setFieldValue('propertyAddress.city', e.target.value)
              }
              displayEmpty
              inputProps={{
                'aria-label': 'Without label',
              }}
              placeholder='choose city...'
              className={
                errors.propertyAddress?.city && touched.propertyAddress?.city
                  ? classes.error
                  : classes.select
              }
              onBlur={handleBlur}
            />

            {errors.propertyAddress?.city && touched.propertyAddress?.city && (
              <Box sx={{ color: 'red', fontSize: '10px' }}>
                {errors.propertyAddress.city}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='property zipcode' title=' Property Zip Code' />
            <InputField
              name='propertyAddress.zipCode'
              placeholder='property zipcode...'
              value={values.propertyAddress.zipCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='property country' title=' Property Country' />
            <SelectField
              value={values.propertyAddress.country}
              options={countries.map((country) => ({
                _id: country.name,
                value: country.name,
              }))}
              className={
                errors.propertyAddress?.country &&
                touched.propertyAddress?.country
                  ? classes.error
                  : classes.select
              }
              onChange={(e) =>
                setFieldValue('propertyAddress.country', e.target.value)
              }
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              placeholder='choose country...'
              onBlur={handleBlur}
            />
            {errors.propertyAddress?.country &&
              touched.propertyAddress?.country && (
                <Box sx={{ color: 'red', fontSize: '10px' }}>
                  {errors.propertyAddress.country}
                </Box>
              )}
          </Box>
        </Grid>
      </Grid>

      <Box className={classes.inputWrapper}>
        <InputLabel id='title document' title=' Title Document' />
        <InputField
          name='propertyAdditionalDetails.title'
          placeholder='title...'
          value={values.propertyAdditionalDetails.title}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>

      <Box className={classes.inputWrapper}>
        <InputLabel id='guaranteed benefits' title=' Guaranteed benefits' />
        <TextAreaField
          name='propertyAdditionalDetails.benefits'
          maxRows={8}
          className={
            errors.propertyAdditionalDetails?.benefits &&
            touched.propertyAdditionalDetails?.benefits
              ? classes.error
              : classes.textArea
          }
          minRows={10}
          placeholder='guaranteed benefits...'
          value={values.propertyAdditionalDetails.benefits}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        {errors.propertyAdditionalDetails?.benefits &&
          touched.propertyAdditionalDetails?.benefits && (
            <Box sx={{ color: 'red', fontSize: '10px' }}>
              {errors.propertyAdditionalDetails.benefits}
            </Box>
          )}
      </Box>
    </Grid>
  );
};

export default RightSideFields;
