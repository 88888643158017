import { FC, useState, useEffect } from 'react';
import { InputField, ActionButton } from 'src/components/Forms';
import {
  TriggerButtons,
  useBottomPopup,
  ChangeConfirmedPopup,
  Table,
} from 'src/components';
import { Seo } from 'src/constants';
import DeletePopUp from './PopUp/DeletePopUp';
import EditPopUp from './PopUp/EditPopUp';
import * as service from 'src/service';
import { IModalDisplay, IModalDisplayEdit } from './utils/types';
import { Box } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { propertyCategoryValidationSchema } from '../utils/validation';
import { propertyCategoryInitialValues } from '../utils/values';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useStyles } from './PropertiesCategoryStyles';

interface IItemCategory {
  id: string;
  name: string;
}
const PropertiesCategory: FC = () => {
  const classes = useStyles();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [categories, setCategories] = useState<IItemCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<IModalDisplayEdit>({
    show: false,
    data: {
      _id: '',
      name: '',
    },
  });
  const [openDeleteModal, setOpenDeleteModal] = useState<IModalDisplay>({
    show: false,
    data: '',
  });

  useEffect(() => {
    setIsLoading(true);
    service.categories_property
      .getCategories()
      .then((res) => {
        setCategories(res.data.categories);
        setIsLoading(false);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [changingConfirmed]);

  const onOpenEditModal = (data: { _id: string; name: string }) => {
    setOpenEditModal((prevState) => ({
      show: !prevState.show,
      data: { ...data },
    }));
  };

  const onCloseEditModal = () => {
    setOpenEditModal((prevState) => ({
      show: !prevState.show,
      data: {
        _id: '',
        name: '',
      },
    }));
  };

  const onOpenDeleteModal = (data: string) => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data,
    }));
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data: '',
    }));
  };

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 300, sortable: false },
    { field: 'name', headerName: ' Name', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <TriggerButtons
          onEdit={() => onOpenEditModal(params.row)}
          onDelete={() => onOpenDeleteModal(params.row._id)}
        />
      ),
    },
  ];

  const onCategorySubmit = (values: FormikValues, actions: any) => {
    service.categories_property
      .createCategory(values)
      .then((res) => {
        console.log(res);
        if (res.response.status === 200) {
          changeContinuePopup('Category created successfully');
          actions.resetForm({
            values: {
              name: '',
            },
          });
          setTimeout(() => {
            changeContinuePopup('Category created successfully');
          }, 3000);
        }
        changeContinuePopup('Category created successfully');
      })
      .catch((error: any) => {
        actions.setSubmitting(false);
        actions.setFieldError('name', error.message);
      });
  };

  const onSaveCategory = (values: FormikValues, actions: any) => {
    const id = openEditModal.data._id;
    service.categories_property
      .updateCategory(id, values)
      .then((res) => {
        if (res.response.status === 200) {
          changeContinuePopup('Category updated successfully');
          setTimeout(() => {
            changeContinuePopup('Category updated successfully');
          }, 3000);
          onCloseEditModal();
        }
      })
      .catch((error: any) => {
        actions.setSubmitting(false);
        actions.setFieldError('name', error.message);
      });
  };

  const onDeleteCategory = (id: string) => {
    service.categories_property
      .deleteCategory(id)
      .then((res) => {
        if (res.response.status === 200) {
          changeContinuePopup('Category deleted successfully');
          setTimeout(() => {
            changeContinuePopup('Category deleted successfully');
          }, 3000);
          onCloseDeleteModal();
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  return (
    <Box>
      <Seo title='Create Landmark' />
      <Box className={classes.titleWrapper}>
        <h1> Create LandMark</h1>
        {changingConfirmed && (
          <ChangeConfirmedPopup
            successText={successText}
            changingConfirmed={changingConfirmed}
          />
        )}
      </Box>

      <Formik
        initialValues={propertyCategoryInitialValues}
        validationSchema={propertyCategoryValidationSchema}
        onSubmit={onCategorySubmit}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleChange,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.inputWrapper}>
              <InputField
                name='name'
                value={values.name}
                onChange={handleChange}
                placeholder='enter landmark name...'
              />
              <ActionButton
                type='submit'
                disabled={isSubmitting || !isValid || !dirty}
                className={classes.btnSubmit}
              >
                Submit
              </ActionButton>
            </Box>

            <Table
              loading={isLoading || !categories}
              rows={(categories && categories) || []}
              columns={columns}
              getRowId={(row) => row._id}
              className={classes.tableWrapper}
            />
          </Form>
        )}
      </Formik>

      {openEditModal.show && (
        <EditPopUp
          onOpenEditModal={openEditModal}
          onCloseEditModal={onCloseEditModal}
          onSaveCategory={onSaveCategory}
        />
      )}
      {openDeleteModal.show && (
        <DeletePopUp
          openDeleteModal={openDeleteModal}
          onCloseDeleteModal={onCloseDeleteModal}
          onDeleteCategory={onDeleteCategory}
        />
      )}
    </Box>
  );
};

export default PropertiesCategory;
