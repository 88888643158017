export const newPasswordInitialValues = {
  password: '',
  confirmPassword: '',
  token: '',
};

export const emailInitialValues = {
  email: '',
};

export const loginInitialValues = {
  email: '',
  password: '',
};

export const registerInitialValues = {
  name: '',
  email: '',
  password: '',
};
