import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  btnUpload: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: '4rem',
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnSubmit: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: '4rem',
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  gridImage: {
    position: 'relative',
  },

  imageSizeThumb: {
    objectFit: 'cover',
    height: '100px',
    width: '100px',
    marginBottom: '1rem',
  },

  trash: {
    color: theme.palette.primary[300],
    cursor: 'pointer',
    position: 'absolute',
    right: '40px',
    top: '5px',
  },
  title: {
    marginBottom: '1rem',
  },
}));
