import * as Yup from 'yup';

export const blogValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .min(10, 'Title must be at least 10 characters')
    .max(300, 'Title must be at most 300 characters'),

  content: Yup.string()
    .required('Content is required')
    .min(10, 'Content must be at least 10 characters'),

  description: Yup.string()
    .required('Description is required')
    .min(50, 'Description must be at least 10 characters')
    .max(500, 'Description must be at most 500 characters'),
});

const addressSchema = Yup.object().shape({
  street: Yup.string().required('Street is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country is required'),
  zipCode: Yup.number()
    .required('Zip Code is required')
    .min(6, 'Zip Code must be at least 6'),
});

const additionalDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  benefits: Yup.string().required('Benefits is required'),
});

export const PropertyValidationSchema = Yup.object().shape({
  propertyName: Yup.string().required('Property Name is required'),
  propertyType: Yup.string().required('Property Type is required'),
  propertyPrice: Yup.number().required('Property Price is required'),
  propertySize: Yup.number().required('Property Size is required'),
  propertyDescription: Yup.string().required(
    'Property Description is required'
  ),
  propertyStatus: Yup.string().required('Property Status is required'),
  propertyAddress: addressSchema,
  propertyAdditionalDetails: additionalDetailsSchema,
  category: Yup.string().required('Category is required'),
});

export const blogCategoryValidationSchema = Yup.object().shape({
  name: Yup.string().required(' category name is required'),
});

export const propertyCategoryValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

export const userValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  role: Yup.string().required('Role is required'),
});

export const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const updateProfileValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

export const createUserValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  role: Yup.string().required('Role is required'),
});
