import { FC, useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigation } from '../../../router/Navigation';
import { SideBar, NavBar } from '../../../components';
import { Box, useMediaQuery, useTheme } from '@mui/material';

interface ISwitcherNavBar {
  routes: {
    path?: string;
    main?: () => JSX.Element;
    label?: string;
    icon?: JSX.Element | null;
    pathNames?: string;
  }[];
}

const AdminNavigation: FC = () => {
  const isNonMobile = useMediaQuery(useTheme().breakpoints.up('sm'));
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  const SwitcherNavBar: FC<ISwitcherNavBar> = ({ routes }) => {
    return (
      <>
        {routes.map((route, index) => (
          <Routes key={index}>
            <Route
              path={route.path}
              element={route.main ? route.main() : undefined}
            />
          </Routes>
        ))}
      </>
    );
  };

  const renderSwitcherRoute = useCallback(() => {
    return <SwitcherNavBar routes={Navigation.adminNavigation} />;
  }, []);

  return (
    <Box
      display={isNonMobile ? 'flex' : 'block'}
      sx={{ width: '100%', height: '100%' }}
    >
      <SideBar
        isNonMobile={isNonMobile}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <NavBar
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />
        <Box sx={{ padding: '0 1.5rem' }}>{renderSwitcherRoute()}</Box>
      </Box>
    </Box>
  );
};

export default AdminNavigation;
