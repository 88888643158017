import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  changeWrapper: {
    opacity: 1,
    visibility: 'visible',
  },

  changingConfirmed: {
    background: theme.palette.primary[200],
    boxShadow: '0 2px 8px rgba(47, 73, 209, 0.16)',
    borderRadius: '8px',
    display: 'grid',
    gridTemplateColumns: 'max-content max-content ',
    gridGap: '1rem',
    alignItems: 'center',
    padding: '8px 40px',
    width: '100%',
    placeItems: 'center',
  },
  successText: {
    color: theme.palette.primary[500],
    fontSize: '1.4rem',
    fontWeight: '500',
  },

  changeConfirmedTwo: {
    background: theme.palette.primary[200],
    boxShadow: '0 2px 8px rgba(47, 73, 209, 0.16)',
    borderRadius: '8px',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridGap: '1rem',
    alignItems: 'center',
    padding: '8px 40px',
    width: '60%',
  },

  changeWrapperUnactive: {
    opacity: 0,
    visibility: 'hidden',
  },

  successTextTwo: {
    color: theme.palette.primary[500],
    fontSize: '1.4rem',
    fontWeight: '500',
  },
}));
