import {
  Dashboard,
  Properties,
  PropertiesCategory,
  Blog,
  BlogCategory,
  Users,
  Profile,
  AllProperties,
  AllBlogs,
  EditProperties,
  EditBlog,
  Banner,
} from '../modules/Admin';
import GroupIcon from '@mui/icons-material/Group';
import GiteIcon from '@mui/icons-material/Gite';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WebIcon from '@mui/icons-material/Web';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

export const Navigation = {
  adminNavigation: [
    {
      path: 'dashboard',
      label: 'Dashboard',
      pathnames: 'dashboard',
      icon: <DashboardIcon />,
      main: () => <Dashboard />,
    },
    {
      label: 'Properties',
      icon: null,
    },
    {
      path: 'properties-post',
      pathnames: 'properties-post',
      label: 'Properties',
      icon: <GiteIcon />,
      main: () => <Properties />,
    },
    {
      path: 'properties-all',
      pathnames: 'properties-all',
      label: 'All Props',
      icon: <InventoryIcon />,
      main: () => <AllProperties />,
    },

    {
      path: 'properties-category',
      pathnames: 'properties-category',
      label: 'LandMark',
      icon: <CategoryIcon />,
      main: () => <PropertiesCategory />,
    },

    {
      path: 'property-edit/:slug',
      pathnames: 'property-edit',
      main: () => <EditProperties />,
    },
    {
      label: 'Blog ',
      icon: null,
    },
    {
      path: 'blog-post',
      pathnames: 'blog-post',
      label: 'Blog',
      icon: <WebIcon />,
      main: () => <Blog />,
    },

    {
      path: 'blogs-all',
      pathnames: 'blogs-all',
      label: 'All Blogs',
      icon: <PostAddIcon />,
      main: () => <AllBlogs />,
    },

    {
      path: 'blog-category',
      pathnames: 'blog-category',
      label: ' Category',
      icon: <CategoryIcon />,
      main: () => <BlogCategory />,
    },

    {
      path: 'blog-edit/:slug',
      pathnames: 'blog-edit',
      main: () => <EditBlog />,
    },
    {
      label: 'Banner',
      icon: null,
    },

    {
      path: 'banner',
      pathnames: 'banner',
      label: 'Banner',
      icon: <ViewCarouselIcon />,
      main: () => <Banner />,
    },

    {
      label: 'Users',
      icon: null,
    },
    {
      path: 'users',
      pathnames: 'users',
      label: 'Users',
      icon: <GroupIcon />,
      main: () => <Users />,
    },
    {
      path: 'profile',
      pathnames: 'profile',
      label: 'Profile',
      icon: <SettingsIcon />,
      main: () => <Profile />,
    },
  ],
};
