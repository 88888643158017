import React, { FC, useState, useEffect } from 'react';
import * as service from 'src/service';
import { Seo } from 'src/constants';
import { FormikValues } from 'formik';
import { ActionButton } from 'src/components/Forms';
import {
  Table,
  TriggerButtons,
  useBottomPopup,
  ChangeConfirmedPopup,
} from 'src/components';

import DeletePopUp from './PopUp/DeletePopUp';
import EditPopUp from './PopUp/EditPopUp';
import CreatePopUp from './PopUp/CreatePopUp';
import { useToggle } from 'src/components';
import { Box } from '@mui/material';
import { IModalDisplay, IModalEditDisplay } from './utils/types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useStyles } from './UserStyles';

const Users: FC = () => {
  const classes = useStyles();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [users, setUsers] = useState<Record<string, any>[]>([]);
  const [openCreateModal, setOpenCreateModal] = useToggle(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<IModalEditDisplay>({
    show: false,
    data: {
      _id: '',
      name: '',
      email: '',
      role: '',
    },
  });
  const [openDeleteModal, setOpenDeleteModal] = useState<IModalDisplay>({
    show: false,
    data: '',
  });

  useEffect(() => {
    setIsLoading(true);
    service.profile
      .getAllUsers()
      .then((res) => {
        setUsers(res.data.users);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onOpenCreateModal = () => setOpenCreateModal.on();
  const onCloseCreateModal = () => setOpenCreateModal.off();

  const onOpenEditModal = (data: {
    _id: string;
    name: string;
    email: string;
    role: string;
  }) => {
    setOpenEditModal((prevState) => ({
      show: !prevState.show,
      data: { ...data },
    }));
  };

  const onCloseEditModal = () => {
    setOpenEditModal((prevState) => ({
      show: !prevState.show,
      data: {
        _id: '',
        name: '',
        email: '',
        role: '',
      },
    }));
  };

  const onOpenDeleteModal = (data: string) => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data,
    }));
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data: '',
    }));
  };

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 200, sortable: false },
    { field: 'name', headerName: ' Name', width: 150 },
    { field: 'email', headerName: ' Email', width: 150 },
    {
      field: 'role',
      headerName: ' Role',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <span style={{ color: params.value === 'admin' ? 'red' : 'green' }}>
          {params.value}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <TriggerButtons
          onEdit={() => onOpenEditModal(params.row)}
          onDelete={() => onOpenDeleteModal(params.row._id)}
        />
      ),
    },
  ];
  const onCreateUser = (values: FormikValues, actions: any) => {
    service.profile
      .createUser(values)
      .then((res) => {
        if (!res.response.ok) throw res;
        return res;
      })
      .then((res) => {
        setUsers((prevState) => [...prevState, res.data.user]);
        changeContinuePopup('User created successfully');
        setTimeout(() => {
          changeContinuePopup('User created successfully');
        }, 3000);
        onCloseCreateModal();
        actions.resetForm();
      })
      .catch((err) => {
        actions.setSubmitting(false);
        actions.setFieldError(
          'email',
          err.response.status === 400
            ? 'Email already used'
            : 'Something went wrong'
        );
      });
  };

  const onSaveUser = (values: FormikValues) => {
    const id = openEditModal.data._id;
    service.profile
      .updateUser(id, values)
      .then((res) => {
        if (res.response.status === 200) {
          setUsers((prevState) => {
            const updatedUsers = prevState.map((user) => {
              if (user._id === id) {
                return { ...user, ...values };
              }
              return user;
            });
            return updatedUsers;
          });
          changeContinuePopup('User updated successfully');
          setTimeout(() => {
            changeContinuePopup('User updated successfully');
          }, 3000);

          onCloseEditModal();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDeleteUser = (id: string) => {
    service.profile
      .deleteUser(id)
      .then((res) => {
        setUsers((prevState) => prevState.filter((user) => user._id !== id));
        changeContinuePopup('User deleted successfully');
        setTimeout(() => {
          changeContinuePopup('User deleted successfully');
        }, 3000);
        onCloseDeleteModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Seo title='Users' />
      <Box className={classes.userWrapper}>
        <h1> Users List</h1>
        {changingConfirmed && (
          <ChangeConfirmedPopup
            successText={successText}
            changingConfirmed={changingConfirmed}
          />
        )}
        <ActionButton
          onClick={onOpenCreateModal}
          className={classes.btnCreate}
          type='submit'
        >
          create
        </ActionButton>
      </Box>

      <Table
        loading={isLoading && !users.length}
        rows={users ? users : []}
        getRowId={(row) => row._id}
        columns={columns}
        className={classes.tableWrapper}
      />

      {openEditModal.show && (
        <EditPopUp
          onOpenEditModal={openEditModal}
          onCloseEditModal={onCloseEditModal}
          onSaveUser={onSaveUser}
        />
      )}
      {openDeleteModal.show && (
        <DeletePopUp
          openDeleteModal={openDeleteModal}
          onCloseDeleteModal={onCloseDeleteModal}
          onDeleteUser={onDeleteUser}
        />
      )}
      {openCreateModal && (
        <CreatePopUp
          onOpenCreateModal={openCreateModal}
          onCloseCreateModal={onCloseCreateModal}
          onCreateUser={onCreateUser}
        />
      )}
    </>
  );
};

export default Users;
