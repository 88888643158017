import { FC } from 'react';
import { Modals } from 'src/components';
import {
  InputField,
  ActionButton,
  SelectField,
  InputLabel,
} from 'src/components/Forms';
import { Form, Formik, FormikValues } from 'formik';
import { createUserValidationSchema } from '../../utils/validation';
import { createUserInitialValues } from '../../utils/values';
import Logo from 'src/assets/logo4.png';
import { Box, Card, CardContent, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../UserStyles';

interface IEditPopUp {
  onOpenCreateModal: boolean;
  onCloseCreateModal: () => void;
  onCreateUser: (values: FormikValues, actions: any) => void;
}
const CreatePopUp: FC<IEditPopUp> = ({
  onOpenCreateModal,
  onCloseCreateModal,
  onCreateUser,
}) => {
  const classes = useStyles();
  const { palette } = useTheme();

  return (
    <Modals openModal={onOpenCreateModal} closeModal={onCloseCreateModal}>
      <Formik
        initialValues={createUserInitialValues}
        validationSchema={createUserValidationSchema}
        onSubmit={onCreateUser}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleChange,
          values,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Card className={classes.modalContent}>
              <CardContent sx={{ p: 0 }}>
                <Box className={classes.modalHeader}>
                  <img src={Logo} alt='logo' className={classes.logo} />
                  <IconButton onClick={onCloseCreateModal}>
                    <CloseIcon sx={{ color: palette.primary[500] }} />
                  </IconButton>
                </Box>
                <Box className={classes.modalWrapper}>
                  <Box className={classes.inputWrapper}>
                    <InputLabel id='name' title=' Name' />
                    <InputField
                      className={classes.inputField}
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box className={classes.inputWrapper}>
                    <InputLabel id='email' title=' Email' />
                    <InputField
                      className={classes.inputField}
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box className={classes.inputWrapper}>
                    <InputLabel id='password' title=' Password' />
                    <InputField
                      className={classes.inputField}
                      name='password'
                      type='password'
                      value={values.password}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box className={classes.inputWrapper}>
                    <InputLabel id='role' title=' Role' />
                    <SelectField
                      value={values.role}
                      name='role'
                      options={[
                        { _id: 'admin', value: 'Admin' },
                        { _id: 'user', value: 'User' },
                      ]}
                      sx={{ minWidth: 100, Height: 40 }}
                      onChange={(e) => setFieldValue('role', e.target.value)}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='choose role...'
                    />
                  </Box>
                  <ActionButton
                    onClick={onCloseCreateModal}
                    className={classes.modalCancel}
                  >
                    Cancel
                  </ActionButton>

                  <ActionButton
                    type='submit'
                    disabled={!isValid || isSubmitting || !dirty}
                    className={classes.modalBtnDelete}
                  >
                    Create
                  </ActionButton>
                </Box>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </Modals>
  );
};

export default CreatePopUp;
