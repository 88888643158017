import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: any) => ({
  appBar: {
    position: 'static',
    background: 'none',
    boxShadow: 'none',
  },

  bugerWrapper: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },

  searchWrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '9px',
    gap: '3rem',
    padding: '0.1rem 1.5rem',
  },

  usersInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },

  userName: {
    color: theme.palette.secondary[100],
    fontWeight: 'bold',
    fontSize: '0.85rem',
  },

  avatar: {
    width: '2.5rem',
    height: '2.5rem',
    objectFit: 'cover',
    borderRadius: '50%',
  },
}));
