import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ICategory = {
  categories: [];
  category: Record<string, any>;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
};

const initialState: ICategory = {
  categories: [],
  category: {},
  status: 'idle',
  error: null,
};

export const blogCategorySlice = createSlice({
  name: 'blogCategory',
  initialState,
  reducers: {
    getAllCategories: (state, action: PayloadAction<ICategory[]>) => {
      state.categories = action.payload as [];
    },
  },
});

export const { getAllCategories } = blogCategorySlice.actions;

export default blogCategorySlice.reducer;
