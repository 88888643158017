import React, { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ListMenu from './ListMenu';
import { useSelector } from 'react-redux';
import { getUserName } from '../../mainstore/selectors';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useStyles } from './SideBarStyles';

interface ISideBar {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
  isNonMobile: boolean;
}
const SideBar: FC<ISideBar> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const userName = useSelector(getUserName);
  const [activeLink, setActiveLink] = useState<string>('');

  useEffect(() => {
    setActiveLink(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component='nav'>
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant='persistent'
          anchor='left'
          className={classes.drawer}
          classes={{ paper: classes.paper }}
        >
          <Box width='100%'>
            <Box className={classes.frontierContainer}>
              <Box className={classes.frontierWrapper}>
                <Typography className={classes.userName}>{userName}</Typography>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(false)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </Box>
            </Box>
            <ListMenu activeLink={activeLink} setActiveLink={setActiveLink} />
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default SideBar;
