import React, { FC, ReactNode, useMemo, useEffect } from 'react';
import { AdminNavigation } from './modules/Admin';
import Keys from 'src/constants/helper';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { isLogin } from 'src/mainstore/selectors';
import { getUserAuth, setIsLoginUser } from 'src/mainstore/features/auth/auth';
import { ScrollTop } from './constants';
import { Registration, ResetPassword } from './modules/Auth';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRouter from './router/PrivateRouter';
import { selectMode } from './mainstore/selectors';
import { theme } from 'src/utils';
import { CssBaseline, Box, ThemeProvider } from '@mui/material';
import { createTheme, StyledEngineProvider } from '@mui/material/styles';

interface AppProps {
  children: ReactNode;
}
interface ITheme {
  [key: string]: any;
}

const App: FC = () => {
  const mode = useSelector(selectMode);
  const isLoginUser = useSelector(isLogin);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const themes = useMemo(
    () => createTheme(theme.themSettings(mode) as ITheme),
    [mode]
  );

  const appHeight = () => {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
  };

  useEffect(() => {
    appHeight();
    window.addEventListener('resize', appHeight);
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(Keys.JWT_TOKEN);
    if (token) {
      dispatch(setIsLoginUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoginUser) {
      dispatch(getUserAuth());
    }
  }, [dispatch, isLoginUser]);

  return (
    <div>
      <BrowserRouter>
        <ScrollTop>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <Routes>
                <Route
                  path='/'
                  element={<Navigate to='/auth?type=login' replace={true} />}
                />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/auth' element={<Registration />} />
                <Route
                  path='/admin/*'
                  element={
                    <PrivateRouter>
                      <WrapperApp>
                        <AdminNavigation />
                      </WrapperApp>
                    </PrivateRouter>
                  }
                />
              </Routes>
            </ThemeProvider>
          </StyledEngineProvider>
        </ScrollTop>
      </BrowserRouter>
    </div>
  );
};

export const WrapperApp: FC<AppProps> = ({ children }) => {
  return (
    <Box className={'wrapper_app'}>
      {/* <Header /> */}
      {children}
      {/* <Footer /> */}
    </Box>
  );
};

export default App;
