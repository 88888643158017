import React, { FC, useState, useRef, useEffect } from 'react';
import { InputField, InputLabel } from 'src/components/Forms';
import { Box, Typography } from '@mui/material';
import { useStyles } from './TagsSuggestionsStyles';

interface TagsSuggestionsProps {
  tags: string[];
  setTags: (tags: string[]) => void;
  suggestedTags: string[];
  text: string;
  setText: (text: string) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputName: string;
}
const TagsSuggestions: FC<TagsSuggestionsProps> = ({
  tags,
  setTags,
  suggestedTags,
  text,
  setText,
  handleBlur,
  handleChange,
  inputName,
}) => {
  const classes = useStyles();
  const [display, setDisplay] = useState<boolean>(false);
  const [popupOffset, setPopupOffset] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setPopupOffset(ref.current.offsetHeight + 4);
    }
  }, [tags]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }

    if (e.key === 'Enter' && text && !tags.includes(text.toLowerCase())) {
      setTags([...tags, text]);
      setText('');
      setTimeout(() => {
        setText('');
      }, 100);
    }
  };

  const addTags = (tag: string) => {
    setTags([...tags, tag]);
  };
  const onDelete = (index: number) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
  };

  const onClickOutside = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setText(e.target.value);
  };

  return (
    <>
      <InputLabel id='tags' title='Tags' />
      <Box className={classes.tagContent}>
        {tags.map((tag, index) => (
          <Box key={index} className={classes.tagWrapper}>
            {tag}
            <Box className={classes.removeTag} onClick={() => onDelete(index)}>
              X
            </Box>
          </Box>
        ))}
        <Box ref={ref} className={classes.inputWrapper}>
          <InputField
            onClick={() => setDisplay(true)}
            onKeyDown={onKeyDown}
            onChange={onChange}
            value={text}
            name={inputName}
            onBlur={handleBlur}
            placeholder={!!tags.length ? '' : 'property tags...'}
            className={classes.tagContent}
            sx={{
              '& fieldset': {
                border: 'none',
              },
            }}
          />
        </Box>

        {display && Boolean(suggestedTags && suggestedTags.length) && (
          <Box
            style={{ top: popupOffset }}
            className={classes.suggestionWrapper}
          >
            <Typography className={classes.textSuggestion}>
              Suggestion Tags
            </Typography>
            <Box className={classes.tagsItems}>
              {suggestedTags?.map((tag: any, index) => (
                <Box
                  onClick={() => addTags(tag._id as string)}
                  key={index}
                  className={classes.tagSuggestions}
                >
                  {tag._id}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TagsSuggestions;
