export const blogInitialValues = {
  title: '',
  content: '',
  description: '',
  tags: [''],
  category: '',
  thumbnail: '',
};

export const propertyInitialValues = {
  propertyName: '',
  propertyType: '',
  propertyPrice: '',
  propertySize: '',
  propertyDescription: '',
  propertyStatus: '',
  propertyFeatured: '',
  propertyImage: [],
  propertyAddress: {
    street: '',
    city: '',
    country: '',
    zipCode: '',
  },
  propertyAdditionalDetails: {
    title: '',
    benefits: '',
  },
  category: '',
};

export const blogCategoryInitialValues = {
  name: '',
};

export const propertyCategoryInitialValues = {
  name: '',
};

export const userInitialValues = {
  name: '',
  email: '',
  role: '',
};

export const changePasswordInitialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const updateProfileInitialValues = {
  name: '',
  email: '',
  password: 'secret',
};

export const createUserInitialValues = {
  name: '',
  email: '',
  password: '',
  role: '',
};
