import React, { FC, useEffect, useState } from 'react';
import { Seo } from 'src/constants';
import EnterEmail from './EnterEmail';
import { useBottomPopup, ChangeConfirmedPopup } from 'src/components';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from 'src/assets/logo4.png';
import EnterNewPassword from './EnterNewPassword';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { useStyles } from './ResetPasswordStyles';

const ResetPassword: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [token, setToken] = useState<string | null>(null);
  const classes = useStyles();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams && urlParams.get('token')) {
      const urlToken = urlParams.get('token');
      setToken(urlToken);
    }
  }, [location.search, navigate]);

  return (
    <>
      <Seo title='Reset Password' />
      {changingConfirmed && (
        <ChangeConfirmedPopup
          successText={successText}
          changingConfirmed={changingConfirmed}
        />
      )}
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        spacing={2}
        height='100vh'
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent sx={{ p: 0 }}>
              <Box className={classes.logoWrapper}>
                <img src={Logo} alt='logo' className={classes.logo} />
              </Box>
              {token ? (
                <EnterNewPassword
                  token={token}
                  changeContinuePopup={changeContinuePopup}
                />
              ) : (
                <EnterEmail changeContinuePopup={changeContinuePopup} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
