import { FC } from 'react';
import { Modals } from 'src/components';
import {
  InputField,
  ActionButton,
  SelectField,
  InputLabel,
} from 'src/components/Forms';
import { Form, Formik, FormikValues } from 'formik';
import { userValidationSchema } from '../../utils/validation';
import Logo from 'src/assets/logo4.png';
import { Box, Card, CardContent, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../UserStyles';

interface IEditPopUp {
  onOpenEditModal: {
    show: boolean;
    data: {
      _id?: string;
      name: string;
      email: string;
      role: string;
    };
  };
  onCloseEditModal: () => void;
  onSaveUser: (values: FormikValues) => void;
}
const EditPopUp: FC<IEditPopUp> = ({
  onOpenEditModal,
  onCloseEditModal,
  onSaveUser,
}) => {
  const classes = useStyles();
  const { palette } = useTheme();

  return (
    <Modals openModal={onOpenEditModal.show} closeModal={onCloseEditModal}>
      <Formik
        initialValues={{
          name: onOpenEditModal.data.name,
          email: onOpenEditModal.data.email,
          role: onOpenEditModal.data.role,
        }}
        validationSchema={userValidationSchema}
        onSubmit={async (values, actions) => {
          onSaveUser(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          values,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Card className={classes.modalContent}>
              <CardContent sx={{ p: 0 }}>
                <Box className={classes.modalHeader}>
                  <img src={Logo} alt='logo' className={classes.logo} />
                  <IconButton onClick={onCloseEditModal}>
                    <CloseIcon sx={{ color: palette.primary[500] }} />
                  </IconButton>
                </Box>
                <Box className={classes.modalWrapper}>
                  <Box className={classes.inputWrapper}>
                    <InputLabel id='name' title=' Name' />
                    <InputField
                      className={classes.inputField}
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box className={classes.inputWrapper}>
                    <InputLabel id='email' title=' Email' />
                    <InputField
                      className={classes.inputField}
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box className={classes.inputWrapper}>
                    <InputLabel id='role' title=' Role' />
                    <SelectField
                      value={values.role}
                      name='role'
                      options={[
                        { _id: 'admin', value: 'Admin' },
                        { _id: 'user', value: 'User' },
                      ]}
                      sx={{ minWidth: 100, Height: 40 }}
                      onChange={(e) => setFieldValue('role', e.target.value)}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='choose role...'
                    />
                  </Box>
                  <ActionButton
                    onClick={onCloseEditModal}
                    className={classes.modalCancel}
                  >
                    Cancel
                  </ActionButton>

                  <ActionButton
                    type='submit'
                    disabled={isSubmitting}
                    className={classes.modalBtnDelete}
                  >
                    Save
                  </ActionButton>
                </Box>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </Modals>
  );
};

export default EditPopUp;
