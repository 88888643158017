import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  drawer: {
    width: '240px',
  },

  logo: {
    width: '150px',
  },

  paper: {
    color: theme.palette.secondary[200],
    backgroundColor: theme.palette.background.paper,
    boxSixing: 'border-box',
    borderWidth: '0 1px 0 0',
    width: '240px',
    overflow: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '7px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary[300],
      borderRadius: '10px',
      backgroundClip: 'content-box',
    },
  },

  frontierContainer: {
    margin: '1.5rem 2rem 2rem 3rem',
  },

  frontierWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.secondary.main,
  },

  labelName: {
    margin: '2.25rem 0 1rem 3rem',
  },

  activeLabel: {
    background: theme.palette.secondary[300],
    color: theme.palette.primary[600],
  },

  inactiveLabel: {
    background: 'transparent',
    color: theme.palette.secondary[100],
  },

  activeIcon: {
    color: theme.palette.primary[600],
    marginLeft: '2rem',
  },

  inactiveIcon: {
    color: theme.palette.secondary[200],
    marginLeft: '2rem',
  },

  userName: {
    color: theme.palette.secondary[100],
    fontWeight: 'bold',
    fontSize: '0.85rem',
  },
}));
