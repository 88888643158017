import { FC } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface ITriggerButtons {
  onEdit: () => void;
  onDelete: () => void;
}
const TriggerButtons: FC<ITriggerButtons> = ({ onEdit, onDelete }) => {
  const { palette } = useTheme();
  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <IconButton onClick={() => onEdit()}>
        <EditIcon style={{ color: palette.secondary[500] }} />
      </IconButton>
      <IconButton onClick={() => onDelete()}>
        <DeleteIcon style={{ color: palette.primary[200] }} />
      </IconButton>
    </Box>
  );
};

export default TriggerButtons;
