import { get, post, put, remove } from './api';

export const createBlog = (body: Record<string, any>) =>
  post({
    url: '/admin/blog',
    body,
  });

export const getBlogs = () => get({ url: '/admin/blogs' });

export const getBlogBySlug = (slug: string) => get({ url: `/blog/${slug}` });

export const getSuggetedTags = (prefix: string) =>
  get({ url: `/blog/tags/${prefix}` });

export const updateBlog = (slug: string, body: Record<string, any>) =>
  put({ url: `/admin/blog/${slug}`, body });

export const deleteBlog = (id: string) => remove({ url: `/admin/blog/${id}` });
