import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as service from 'src/service';
import Keys from 'src/constants/helper';

export interface UserAuthState {
  token?: string;
  user?: {
    email?: string;
    role?: string;
    userId?: string;
    _id?: string;
    name?: string;
  };
  isLogin?: boolean;
}

const initialState: UserAuthState = {
  token: '',
  user: {
    email: '',
    role: '',
    userId: '',
    _id: '',
    name: '',
  },
  isLogin: !!localStorage.getItem(Keys.JWT_TOKEN),
};

export const getUserAuth = createAsyncThunk(
  'userAuth/getUserAuth',
  async () => {
    const response = await service.profile.getCurrentUser();
    return response.data;
  }
);
const userAuth = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<UserAuthState>) {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    setIsLoginUser(state) {
      state.isLogin = !!localStorage.getItem(Keys.JWT_TOKEN);
    },
    setLogoutUser(state) {
      state.token = '';
    },

    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUserAuth.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.user = action.payload.user;
      }
    );
  },
});

export const { setUserData, setIsLoginUser, setLogoutUser, setToken } =
  userAuth.actions;

export default userAuth.reducer;
