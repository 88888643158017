import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Seo } from 'src/constants';
import { editBlog } from 'src/utils/routing';
import Table from 'src/components/Table';
import DeletePopUp from './PopUp/DeletePopUp';
import {
  TriggerButtons,
  useBottomPopup,
  ChangeConfirmedPopup,
} from 'src/components';

import * as service from 'src/service';
import moment from 'moment';
import { Box } from '@mui/material';
import { IModalDisplay } from './utils/types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useStyles } from './AllBlogsStyles';

const AllBlogs: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [blogs, setBlogs] = useState<Record<string, any>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openDeleteModal, setOpenDeleteModal] = useState<IModalDisplay>({
    show: false,
    data: '',
  });

  useEffect(() => {
    setIsLoading(true);
    service.blog
      .getBlogs()
      .then((res) => {
        setBlogs(res.data.blogs);
        setIsLoading(false);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [changingConfirmed]);

  const onOpenDeleteModal = (data: string) => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data,
    }));
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal((prevState) => ({
      show: !prevState.show,
      data: '',
    }));
  };

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 200, sortable: false },
    { field: 'title', headerName: ' Title', width: 120 },
    {
      field: 'user',
      headerName: 'Author',
      width: 130,
      renderCell: (params: GridRenderCellParams) => (
        <span>{params.row?.user?.name}</span>
      ),
    },
    {
      field: 'createdAt',
      headerName: ' CreatedAt',
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <span>{moment(params.value).format('DD-MM-YYYY')}</span>
      ),
    },
    { field: 'description', headerName: ' Description', width: 120 },
    {
      field: 'category',
      headerName: 'Category',
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <span>{params.row?.category}</span>
      ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <TriggerButtons
          onEdit={() => navigate(`${editBlog}/${params.row.slug}`)}
          onDelete={() => onOpenDeleteModal(params.row._id)}
        />
      ),
    },
  ];

  const onDeleteBlog = (id: string) => {
    service.blog
      .deleteBlog(id)
      .then((res) => {
        onCloseDeleteModal();
        changeContinuePopup('Blog deleted successfully');
        setTimeout(() => {
          changeContinuePopup('Blog deleted successfully');
        }, 3000);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <Seo title='Blogs' />
      <Box className={classes.titleWrapper}>
        <h1> Blog Posts </h1>
        {changingConfirmed && (
          <ChangeConfirmedPopup
            successText={successText}
            changingConfirmed={changingConfirmed}
          />
        )}
      </Box>

      <Table
        loading={isLoading || !blogs}
        rows={(blogs && blogs) || []}
        columns={columns}
        className={classes.tableWrapper}
        getRowId={(row) => row._id}
      />

      {openDeleteModal.show && (
        <DeletePopUp
          openDeleteModal={openDeleteModal}
          onCloseDeleteModal={onCloseDeleteModal}
          onDeleteBlog={onDeleteBlog}
        />
      )}
    </Box>
  );
};

export default AllBlogs;
