import React, { FC, useState, useEffect } from 'react';
import {
  InputField,
  ActionButton,
  TextAreaField,
  SelectField,
  InputLabel,
} from 'src/components/Forms';
import { FormikValues } from 'formik';
import { propertyType, propertyStatus } from '../data/countries';
import * as service from 'src/service';
import { Box, Grid } from '@mui/material';
import { useStyles } from '../PropertiesStyles';

interface ILeftSideFields {
  edit?: boolean;
  values: FormikValues;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  isMobile: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  dirty: boolean;
  errors: { [key: string]: any };
  touched: { [key: string]: any };
}
interface IBlogCategory {
  _id: string;
  name: string;
}
const LeftSideFields: FC<ILeftSideFields> = ({
  edit,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  isMobile,
  isSubmitting,

  errors,
  touched,
}) => {
  const classes = useStyles();
  const [categories, setCategories] = useState<IBlogCategory[]>([]);

  useEffect(() => {
    service.categories_property
      .getCategories()
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Box className={classes.inputWrapper}>
        <InputLabel id='property name' title=' Property Name' />
        <InputField
          name='propertyName'
          placeholder='property name...'
          onChange={handleChange}
          value={values.propertyName}
          onBlur={handleBlur}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='property size' title=' Property Size' />
            <InputField
              name='propertySize'
              type='number'
              placeholder='property size...'
              value={values.propertySize}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='property price' title=' Property Price' />
            <InputField
              name='propertyPrice'
              type='number'
              placeholder='property price...'
              value={values.propertyPrice}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='property Status' title=' Property Status' />
            <SelectField
              value={values.propertyStatus}
              options={propertyStatus.map((item) => ({
                _id: item._id,
                value: item.value,
              }))}
              onChange={(e) => setFieldValue('propertyStatus', e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              placeholder='choose status...'
              className={
                errors.propertyStatus && touched.propertyStatus
                  ? classes.error
                  : classes.select
              }
              onBlur={handleBlur}
            />
            {errors.propertyStatus && touched.propertyStatus && (
              <Box sx={{ color: 'red', fontSize: '10px' }}>
                {errors.propertyStatus}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='landmark' title=' Landmark' />
            <SelectField
              value={values.category && values.category}
              options={categories.map((item) => ({
                _id: item.name,
                value: item.name,
              }))}
              onChange={(e) => setFieldValue('category', e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              placeholder='choose cities...'
              className={
                errors.category && touched.category
                  ? classes.error
                  : classes.select
              }
              onBlur={handleBlur}
            />
            {errors.category && touched.category && (
              <Box sx={{ color: 'red', fontSize: '10px' }}>
                {errors.category}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel id='property type' title=' Property Type' />
            <SelectField
              value={values.propertyType}
              options={propertyType.map((item) => ({
                _id: item._id,
                value: item.value,
              }))}
              onChange={(e) => setFieldValue('propertyType', e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              placeholder='choose propertyType...'
              className={
                errors.propertyType && touched.propertyType
                  ? classes.error
                  : classes.select
              }
              onBlur={handleBlur}
            />
            {errors.propertyType && touched.propertyType && (
              <Box sx={{ color: 'red', fontSize: '10px' }}>
                {errors.propertyType}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.inputWrapper}>
            <InputLabel
              id='property featured'
              title=' Best selling location (optional)'
            />
            <InputField
              name='propertyFeatured'
              placeholder='location...'
              value={values.propertyFeatured}
              onChange={handleChange}
            />
          </Box>
        </Grid>
      </Grid>

      <Box className={classes.inputWrapper}>
        <InputLabel id='property description' title=' Property Description' />
        <TextAreaField
          name='propertyDescription'
          value={values.propertyDescription}
          onChange={handleChange}
          placeholder='property description...'
          className={
            errors.propertyDescription && touched.propertyDescription
              ? classes.error
              : classes.textArea
          }
          maxRows={8}
          minRows={10}
          onBlur={handleBlur}
        />

        {errors.propertyDescription && touched.propertyDescription && (
          <Box sx={{ color: 'red', fontSize: '10px' }}>
            {errors.propertyDescription}
          </Box>
        )}
      </Box>
      {!isMobile && (
        <ActionButton
          type='submit'
          className={classes.btnSubmit}
          disabled={isSubmitting}
        >
          {edit ? 'Save' : 'Submit'}
        </ActionButton>
      )}
    </Grid>
  );
};

export default LeftSideFields;
