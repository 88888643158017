import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputField, ActionButton, InputLabel } from 'src/components/Forms';
import { login, resetPassword } from 'src/utils/routing';
import * as service from 'src/service';
import { Form, Formik, FormikValues } from 'formik';
import { PasswordValidationSchema } from '../../utils/validation';
import { newPasswordInitialValues } from '../../utils/values';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, InputAdornment, IconButton } from '@mui/material';
import { useStyles } from '../ResetPasswordStyles';

interface IEnterNewPassword {
  token: string;
  changeContinuePopup: (message: string) => void;
}

const EnterNewPassword: FC<IEnterNewPassword> = ({
  token,
  changeContinuePopup,
}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onChangePassword = (values: FormikValues, actions: any) => {
    service.auth
      .resetPassword(token, values)
      .then((res) => {
        if (!res.response.ok) throw res;
        return res;
      })
      .then((res) => {
        if (res.response.status === 200) {
          actions.resetForm({
            values: newPasswordInitialValues,
          });
          changeContinuePopup('Password changed successfully');
          setTimeout(() => {
            changeContinuePopup('Password changed successfully');
            navigate(login);
          }, 3000);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          actions.setFieldError(
            'token',
            err.response.status === 400
              ? 'Your token has expired. Please resend email to get new token'
              : 'Something went wrong'
          );
        }, 2000);
      });
  };

  const classes = useStyles();
  return (
    <Box className={classes.inputContainer}>
      <Formik
        initialValues={newPasswordInitialValues}
        validationSchema={PasswordValidationSchema}
        onSubmit={onChangePassword}
      >
        {({
          handleSubmit,
          errors,
          touched,
          handleChange,
          values,
          isSubmitting,
          isValid,
          dirty,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.inputWrapper}>
              <InputLabel id='password' title='Password' />
              <InputField
                type={showPassword ? 'password' : 'text'}
                name='password'
                placeholder='enter password...'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box className={classes.inputWrapper}>
              <InputLabel id='confirmPassword' title='Confirm Password' />
              <InputField
                type={showPassword ? 'password' : 'text'}
                name='confirmPassword'
                placeholder='enter password again...'
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {errors.token && touched.token ? (
              <Box className={classes.error}>{errors.token}</Box>
            ) : null}

            <Box className={classes.btnGroup}>
              <ActionButton
                disabled={isSubmitting || !isValid || !dirty}
                onClick={() => {
                  if (errors.token && touched.token) {
                    navigate(resetPassword);
                  }
                }}
                type={errors.token && touched.token ? 'button' : 'submit'}
                className={classes.btnPassword}
              >
                {errors.token && touched.token ? 'Go back' : 'Confirm Password'}
              </ActionButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EnterNewPassword;
