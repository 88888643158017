import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { InputField, ActionButton, InputLabel } from 'src/components/Forms';
import { Form, Formik, FormikValues } from 'formik';
import { LoginValidationSchema } from '../../utils/validation';
import { loginInitialValues } from '../../utils/values';
import { resetPassword } from 'src/utils/routing';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography, Box, InputAdornment, IconButton } from '@mui/material';
import { useStyles } from '../RegisterStyles';

interface ILogin {
  isLoading: boolean;
  onLogin: (values: FormikValues, actions: any) => void;
}
const Login: FC<ILogin> = ({ isLoading, onLogin }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Box className={classes.inputContainer}>
      <Formik
        initialValues={loginInitialValues}
        validationSchema={LoginValidationSchema}
        onSubmit={onLogin}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleChange,
          handleBlur,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.inputWrapper}>
              <InputLabel id='email' title='Email' />
              <InputField
                type='email'
                name='email'
                placeholder='enter email..'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box className={classes.inputWrapper}>
              <InputLabel id='password' title='Password' />
              <InputField
                type={showPassword ? 'password' : 'text'}
                name='password'
                placeholder='enter password...'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={classes.forgot}>
              <Typography variant='body2' component='p' gutterBottom>
                <Link to={resetPassword} className={classes.link}>
                  Forgot password?
                </Link>
              </Typography>
            </Box>

            <ActionButton
              type='submit'
              disabled={!isValid || isSubmitting || !dirty}
              className={classes.btnSubmit}
            >
              submit
            </ActionButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Login;
