import React, { FC } from 'react';
import { InputField, ActionButton, InputLabel } from 'src/components/Forms';
import { FormikValues } from 'formik';
import cx from 'classnames';
import { Box, Grid } from '@mui/material';
import { useStyles } from '../ProfileStyles';

interface IRightSide {
  onOpenPasswordModal: () => void;
  isFocused: boolean;
  onCancel: () => void;
  onFocused: () => void;
  values: FormikValues;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const RightSide: FC<IRightSide> = ({
  onOpenPasswordModal,
  isFocused,
  onCancel,
  onFocused,
  values,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={7}>
      <Box className={classes.rightGrid}>
        <Box className={classes.inputWrapper}>
          <InputLabel id=' name' title=' Name' />
          <InputField
            name='name'
            onFocus={onFocused}
            onChange={handleChange}
            value={values.name}
          />
        </Box>
        <Box className={classes.inputWrapper}>
          <InputLabel id='email' title=' Email' />
          <InputField name='email' value={values.email} disabled />
        </Box>

        <Box sx={{ mb: 1 }}>
          <InputLabel id='password' title=' Password' />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className={cx(classes.inputWrapper, classes.inputPassword)}>
              <InputField
                name='password'
                type='password'
                value={values.password}
                disabled
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ActionButton
              onClick={onOpenPasswordModal}
              className={classes.btnModify}
            >
              modify
            </ActionButton>
          </Grid>
        </Grid>
        <Box className={classes.btnGroup}>
          {isFocused && (
            <ActionButton type='submit' className={classes.btnSave}>
              Save
            </ActionButton>
          )}
          {isFocused && (
            <ActionButton onClick={onCancel} className={classes.btnCancel}>
              Cancel
            </ActionButton>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default RightSide;
