import { get, post, put, remove } from './api';

export const createAvatar = (body: Record<string, any>) =>
  post({ url: '/admin/avatar', body });

export const getAvatar = () => get({ url: '/admin/avatar' });

export const updateAvatar = (id: string, body: Record<string, any>) =>
  put({ url: `/admin/avatar/${id}`, body });

export const deleteAvatar = (id: string) =>
  remove({
    url: `/admin/avatar/${id}`,
  });
