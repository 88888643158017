export const login = '/auth/?type=login';
export const register = '/auth/?type=sign-up';
export const resetPassword = '/reset-password';
export const editBlog = '/admin/blog-edit';
export const dashboard = '/admin/dashboard';
export const editProperty = '/admin/property-edit';
export const property = '/admin/properties-all';
export const blog = '/admin/blogs-all';
export const users = '/admin/users';
export const categories = '/admin/properties-category';
