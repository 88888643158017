import { FC } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6

interface IEditor {
  value: string;
  setFieldValue: (field: string, value: any) => void;
  errors: { [key: string]: any };
  touched: { [key: string]: boolean };
}
const Editor: FC<IEditor> = ({ value, setFieldValue, errors, touched }) => {
  const modules = { toolbar: { container } };
  return (
    <>
      <div>
        <ReactQuill
          theme='snow'
          modules={modules}
          placeholder='Write something...'
          value={value}
          onChange={(e) => setFieldValue('content', e)}
          // onBlur={(e) => handleBlur(e as any)}
          style={{ color: 'white' }}
        ></ReactQuill>
      </div>
      {errors.content && touched.content && (
        <div style={{ color: 'red', fontSize: '10px' }}>{errors.content}</div>
      )}
    </>
  );
};
const container = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['clean', 'link', 'image', 'video'], // remove formatting button
];

export default Editor;
