import { get, post, put, remove } from './api';

export const createCategory = (body: Record<string, any>) =>
  post({
    url: '/admin/property/category',
    body,
  });

export const getCategories = () => get({ url: '/admin/property/categories' });

export const updateCategory = (id: string, body: Record<string, any>) =>
  put({ url: `/admin/property/category/${id}`, body });

export const deleteCategory = (id: string) =>
  remove({ url: `/admin/property/category/${id}` });
