import { FC, ReactElement } from 'react';
import { Box, Modal } from '@mui/material';

interface IModals {
  openModal: boolean;
  closeModal: () => void;
  children: ReactElement;
}

const Modals: FC<IModals> = ({ openModal, closeModal, children }) => {
  return (
    <Box sx={{ outline: 'none' }}>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby='logout-pop-title'
        aria-describedby='log out modal'
      >
        {children}
      </Modal>
    </Box>
  );
};

export default Modals;
