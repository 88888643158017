import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { useStyles } from './StatBoxStyles';

interface IStatBox {
  title: string;
  value: number;
  icon: React.ReactNode;
  description: string;
  path: string;
}

const StatBox: FC<IStatBox> = ({ title, value, icon, description, path }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '1.25rem 1rem',
        flex: '1 1 100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '0.55rem',
        cursor: 'pointer',
      }}
      onClick={() => navigate(path)}
    >
      <Box className={classes.statsWrapper}>
        <Typography variant='h6' sx={{ color: theme.palette.secondary[100] }}>
          {title}
        </Typography>
        {icon}
      </Box>

      <Typography
        variant='h3'
        fontWeight='600'
        sx={{ color: theme.palette.secondary[200] }}
      >
        {value && value}
      </Typography>
      <Box className={classes.statsWrapper}>
        <Typography>{description}</Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
