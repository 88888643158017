import React, { FC } from 'react';
import { InputLabel } from 'src/components/Forms';
import { FormikValues } from 'formik';
import { Editor } from 'src/components';
import { styled } from '@mui/material/styles';
import { Box, Grid, Button } from '@mui/material';
import { useStyles } from '../BlogStyles';

interface ILeftSideFields {
  preview: string;
  values: FormikValues;
  errors: { [key: string]: any };
  touched: { [key: string]: any };
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: any) => void;
  setPreview: (preview: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = styled('input')({
  display: 'none',
});

const RightSideFields: FC<ILeftSideFields> = ({
  preview,
  values,
  errors,
  touched,
  setFieldValue,
  setPreview,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6}>
      <Box>
        <label htmlFor='contained-button-file'>
          <Input
            accept='image/*'
            id='contained-button-file'
            multiple
            type='file'
            name='thumbnail'
            onChange={(e) => {
              const file = e.target.files![0];
              const reader = new FileReader();
              reader.onload = () => {
                if (reader.readyState === 2) {
                  setPreview(reader.result as string);
                  setFieldValue('thumbnail', reader.result as string);
                }
              };

              reader.readAsDataURL(file);
            }}
          />
          <Button
            className={classes.btnUpload}
            variant='contained'
            component='span'
          >
            Upload blog image
          </Button>
        </label>
        <Box className={classes.ImageWrapper}>
          {preview && (
            <img
              src={preview}
              alt='thumbnail'
              className={classes.thumbnailImg}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.inputWrapper}>
        <InputLabel id='content' title=' Content' />
        <Editor
          value={values.content}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </Box>
    </Grid>
  );
};

export default RightSideFields;
