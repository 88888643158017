import { configureStore } from '@reduxjs/toolkit';
import modeReducer from '../features/mode/modeSlice';
import blogCategoryReducer from '../features/blogCategory/addCategory';
import userAuthReducer from '../features/auth/auth';
export const store = configureStore({
  reducer: {
    mode: modeReducer,
    blogCategory: blogCategoryReducer,
    auth: userAuthReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
