import { FC, useState, useEffect } from 'react';
import { ActionButton } from 'src/components/Forms';
import { Loader } from 'src/components';
import * as service from 'src/service';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useStyles } from './BannerStyles';

const Input = styled('input')({
  display: 'none',
});

const Banner: FC = () => {
  const classes = useStyles();
  const [imagesPreview, setImagesPreview] = useState<string>('');
  const [banner, setBanner] = useState<string>('');
  const [images, setImages] = useState<Record<string, any>[]>([]);
  const [hoverPreview, setHoverPreview] = useState<{
    [key: number | string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    service.banner
      .getBanners()
      .then((res) => {
        setImages(res.data.banner);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onMouseOver = (index: number | string) => {
    setHoverPreview((prev) => ({ ...prev, [index]: true }));
  };

  const onMouseOut = (index: number | string) => {
    setHoverPreview((prev) => ({ ...prev, [index]: false }));
  };

  const onCreateBanner = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      banner,
    };
    service.banner.createBanner(data).then((res) => {
      setImagesPreview('');
      return setImages((prev) => {
        return [...prev, res.data.banner];
      });
    });
  };

  const onDeleteBanner = (id: string) => {
    service.banner.deleteBanner(id).then((res) => {
      setImages((prev) => {
        return prev.filter((image) => image._id !== id);
      });
    });
  };

  return (
    <Box>
      <form onSubmit={onCreateBanner} encType='multipart/form-data'>
        <label htmlFor='contained-button-file'>
          <Input
            accept='image/*'
            id='contained-button-file'
            multiple
            type='file'
            name='banner'
            onChange={(e) => {
              const file = e.target.files![0];
              const reader = new FileReader();
              reader.onload = () => {
                if (reader.readyState === 2) {
                  setImagesPreview(reader.result as string);
                  setBanner(reader.result as string);
                }
              };

              reader.readAsDataURL(file);
            }}
          />
          <Button
            className={classes.btnUpload}
            variant='contained'
            component='span'
          >
            Upload Banner images
          </Button>
        </label>

        <Grid container spacing={{ xs: 1, md: 1 }}>
          {imagesPreview && (
            <Grid item xs={2} sm={3} md={2} className={classes.gridImage}>
              <img
                src={imagesPreview}
                alt='banner'
                className={classes.imageSizeThumb}
              />
            </Grid>
          )}
        </Grid>
        <ActionButton
          disabled={imagesPreview.length === 0}
          type='submit'
          className={classes.btnSubmit}
        >
          Create Banner
        </ActionButton>
      </form>

      <Typography variant='h5' className={classes.title}>
        Banner
      </Typography>

      <Grid container spacing={{ xs: 1, md: 1 }}>
        {isLoading ? (
          <Loader />
        ) : (
          images.map((image, index) => (
            <Grid
              item
              key={image?._id}
              xs={2}
              sm={3}
              md={2}
              className={classes.gridImage}
              onMouseOver={() => onMouseOver(index)}
              onMouseOut={() => onMouseOut(index)}
            >
              <img
                src={image?.banner?.url}
                alt='banner'
                className={classes.imageSizeThumb}
              />
              {hoverPreview[index] && (
                <IconButton
                  onClick={() => onDeleteBanner(image._id)}
                  className={classes.trash}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default Banner;
