import { get, post, put } from './api';

export const login = (body: Record<string, any>) =>
  post({ url: '/login', body });

export const register = (body: Record<string, any>) =>
  post({ url: '/register', body });

export const logout = () => get({ url: '/logout' });

export const forgotPassword = (body: Record<string, any>) =>
  post({ url: '/forgot/password', body });

export const resetPassword = (token: string, body: Record<string, any>) =>
  put({ url: `/password/reset/${token}`, body });
