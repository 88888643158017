import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: any) => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    marginBottom: '1rem',
  },

  btnUpload: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: '4rem',
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnSubmit: {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    padding: '0.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    marginBottom: '2rem',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  thumbnailImg: {
    objectFit: 'cover',
    height: '100px',
    width: '100px',
  },

  ImageWrapper: {
    position: 'relative',
  },

  trash: {
    color: theme.palette.primary[300],
    cursor: 'pointer',
    position: 'absolute',
    left: '80px',
    bottom: '85px',
  },

  textArea: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },

  error: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.error.main}`,
  },
}));
